$bp-header-sm: 768px;


.header {
	position: absolute;
	top: 24px;
	left: 0;
	z-index: $z_index-level5;
	right: 0;

	@include respond($bp-header-sm) {
		top: 22px;
	}
}


.header .container {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	padding: 0 24px;

	@include respond($bp-padding) {
		padding: 0 32px;
	}
}

.header-logo {
	@include respond($bp-header-sm, "down") {
		width: 114px;
		height: auto;

		svg {
			max-width: 114px;
		}
	}

	svg {
		width: 100%;
		height: auto;
		max-width: 143px;
	}
}

.hamburger {
	width: 48px;
	height: 40px;
	background: transparent;
	padding: 0 5px;
	margin: 0 -5px 0 0;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;

	.bar {
		background: #fff;
		height: 3px;
		width: 48px;
		flex: 0 0 3px;
		transition: width 0.4s ease;

		&:nth-child(2) {
			margin: 6px 0;
		}
	}

	&:hover,
	&:focus {
		.bar:nth-child(3) {
			width: calc(100% - 12px);
		}
	}
}

.header-sticky {
	position: fixed;
	top: -99px;
	left: 0;
	right: 0;
	background: #fff;
	padding: 22px 0 24px 0;
	transition: top 0.4s ease;

	.header-logo svg * {
		fill: $color-body;
	}

	.hamburger .bar {
		background: $color-body;
	}

	&.is-visible {
		top: 0;
	}
}


// -----------------------------------------------------------------------------
//! No Hero Image headers
// -----------------------------------------------------------------------------

.page-privacy-policy,
.page-terms-of-use,
.page-log-in,
.page-studio-policies,
.team-member {
	.header-logo svg * {
		fill: $color-body;
	}

	.nav .header-logo svg * {
		fill: #fff;
	}

	.hamburger .bar {
		background: $color-body;
	}
}