.video-container {
	position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  pointer-events: none;
	  overflow: hidden;

	  
	  
	.r-hero-media-iframe {
		  position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			transform: translate(-50%, -50%);
	}
	
	
	@media (min-aspect-ratio: 16/9) {
	  .r-hero-media-iframe {
		/* height = 100 * (9 / 16) = 56.25 */
		height: 56.25vw;
	  }
	}
	@media (max-aspect-ratio: 16/9) {
	  .r-hero-media-iframe {
		/* width = 100 / (9 / 16) = 177.777777 */
		width: 177.78vh;
	  }
	}
	
}