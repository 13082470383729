$bp-expanders-xl: 1200px;
$bp-expanders-stack: 800px;

.expanders {
	padding: 80px 0;
	position: relative;
	@include respond($bp-padding) {
		padding: 106px 0 134px 0;
	}
	&:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		height: 100%;
		background: linear-gradient(180deg, rgba(230, 231, 232, 0.4) 0%, rgba(230, 231, 232, 0) 100%);
		transform: rotate(180deg);
		max-height: 1100px;
	}
	&.no-bg:after {
		display: none;
	}
	&:before {
		content: "";
		position: absolute;
		width: 15px;
		height: 116px;
		left: 0;
		top: 80px;
		background-image: url(../images/diagonal-dark.png);
		background-repeat: repeat-y;
		background-position: left top;
		background-size: 100% auto;
		display: none;
		@include respond($bp-expanders-stack) {
			width: 22px;
			display: block;
		}
		@include respond($bp-padding) {
			bottom: 134px;	
			top: auto;
			height:292px;
		}
	}
}


.expanders .container {
	position: relative;
	z-index: $z_index-level1;
	@include respond($bp-expanders-stack) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		max-width: 1234px;
	}
}

.expanders-image {
	flex: 1 1 562px;
	max-width: 562px;
	position: relative;
	opacity: 0;
	transform: translateX(-80px);
	transition: opacity 0.4s ease, transform 0.8s ease;
	&:after {
		content: "";
		position: absolute;
		width: 15px;
		height: 116px;
		right: calc(100% + 17px);
		bottom: 0;
		background-image: url(../images/diagonal-dark.png);
		background-repeat: repeat-y;
		background-position: left top;
		background-size: 100% auto;
		@include respond($bp-expanders-stack) {
			display: none;
		}
	}
	&:before {
		content: "";
		position: absolute;
		width: 78px;
		height: 36px;
		background-image: url(../svg/xx.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		top: 50%;
		left:-24px;
		z-index: $z_index-level2 + 1;
		@include respond(768px) {
			width: 91px;
			height: 42px;
			top: 24px;
			left: calc(100% - 29px);
		}
		@include respond($bp-expanders-stack) {
			display: none;
		}
		@include respond($bp-expanders-xl) {
			top: 14%;	
		}
	}
	img {
		display: block;
		position: relative;
		z-index: $z_index-level1;
		border-radius: 3px;
	}	
}


.expanders-image-inner-wrap {
	padding-top: (726 / 562) * 100%;
	position: relative;
}

.expanders-image-inner {	
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	opacity: 0;
	border-radius: 3px;
	position: absolute;
	top:0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: $z_index-level1;
	transition: opacity 0.4s ease;
	&.is-active {
		opacity: 1; 
		z-index: $z_index-level1 + 1;
	}
	&.lazyload {
		background-image: none !important;
	}
}

.expanders-content {
	order: 2;
	flex: 1 1 auto;
	padding: 0 0 48px 0;
	@include respond($bp-expanders-stack) {
		max-width: 50%;
		padding: 96px 0 0 24px;	
	}
	@include respond($bp-padding) {
		padding: 96px 0 0 64px;
	}
	@include respond($bp-expanders-xl) {
		padding: 96px 0 0 128px;	
	}
	
	.btn {
		margin: 30px 0 0 0;
		@include respond($bp-expanders-stack) {
			margin: 50px 0 0 0;	
		}
	}
}

.page-covid-19 .expanders-content {
	@include respond($bp-expanders-stack) {
		padding-top: 26px;	
	}
}

.expanders-title {
	font-family: $font;
	line-height: 1;
	font-size: 2.2rem;
	font-weight: 800;
	font-style: italic;
	text-transform: uppercase;
	margin: 0 0 16px 0;
	position: relative;
	padding: 0 30px 0 0;
	opacity: 0;
	transform: translateY(80px);
	transition: opacity 0.4s ease, transform 0.8s ease;
	@include respond($bp-padding) {
		font-size: 2.8rem;	
		margin: 0 0 24px 0;
	}
	&:after,
	&:before {
		content: "";
		position: absolute;
		top: 4px;
		background: $color-gold;
	}
	&:after {
		height: 6px;
		width: 18px;
		right: 0;
		transform: translateY(6px);
	}
	&:before {
		width: 6px;
		height: 18px;
		right: 6px;
	}
	
}


.expanders-text {
	font-size: 1.6rem;
	line-height: calc(25/16);
	margin: 0 0 24px 0;
	position: relative;
	transform: translateY(40px);
	opacity: 0;
	transition: opacity 0.4s ease 0.2s, transform 0.8s ease 0.2s;
	@include respond($bp-expanders-stack) {
		margin: 0 0 34px 0;
	}
}

.is-scrolled {
	.expanders-image,
	.expanders-title,
	.expanders-text {
		transform: translate(0) !important;
		opacity: 1;
	}
}


#arriving {
	padding-top: 32px;
	.section-title {
		opacity: 0;
		transform: translateY(80px);
		transition: transform 0.8s ease, opacity 0.8s ease;
	}
	
	.is-scrolled .section-title {
		opacity: 1;
		transform: translateY(0);
	}
}