$bp-blog-stack: 768px;
$bp-blog-stack2: 940px;

// -----------------------------------------------------------------------------
//! Blog Page Title
// -----------------------------------------------------------------------------

.page-blog .pageTitle {
	overflow: hidden;

	@include respond($bp-blog-stack) {
		padding-top: 247px;
		padding-bottom: 80px;

		&:after {
			bottom: -50px;
		}

		.hero-text {
			margin-top: 0;
			margin-bottom: 0;
		}

		.hero-title {
			font-size: 5.4rem;
			margin-bottom: 0;
		}
	}
}

// -----------------------------------------------------------------------------
//! Blog Pinned Post
// -----------------------------------------------------------------------------

.pinned-post {
	padding: 80px 0;
	margin: 0;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		height: 100%;
		background: linear-gradient(180deg, rgba(230, 231, 232, 0.4) 0%, rgba(230, 231, 232, 0) 100%);
		transform: rotate(180deg);
		max-height: 1100px;
		z-index: $z_index-level1;
	}

	&:after {
		content: "";
		position: absolute;
		right: 0;
		bottom: 0;
		transform: translate(-130px, 50%);
		width: 122px;
		height: 56px;
		background-image: url(../svg/xx.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		z-index: $z_index-level3;

		@include respond($bp-blog-stack, "down") {
			width: 78px;
			height: 36px;
			transform: translate(-34px, 50%);
		}
	}
}

.pinned-post .container {
	max-width: 1212px + 64px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 48px;

	@include respond($bp-blog-stack2) {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		gap: 64px;
	}
}

.pinned-image {
	border-radius: 3px;
	overflow: hidden;
	position: relative;
	z-index: $z_index-level1;
	opacity: 0;
	transition: transform 0.8s ease, opacity 0.8s ease;
	transform: translateX(-80px);

	@include respond($bp-blog-stack2) {
		flex: 1 1 620px;
		max-width: 620px;
	}

	img {
		display: block;
	}

	img {
		transform: scale(1);
		transition: transform 0.8s ease
	}

	&:hover img {
		transform: scale(1.1);
	}
}

.pinned-content {
	flex: 1 1 528px;
	max-width: 528px;
	position: relative;
	z-index: $z_index-level1 + 1;
	display: flex;
	flex-direction: column;
	gap: 24px;
	justify-content: flex-start;
	align-items: stretch;
	transform: translateX(80px);
	opacity: 0;
	transition: transform 0.8s ease, opacity 0.8s ease;

	h2 {
		position: relative;
		z-index: $z_index-level2;
		font-family: $font;
		font-size: 2.2rem;
		line-height: 1;
		letter-spacing: -0.02em;
		font-style: italic;
		text-transform: uppercase;
		margin: 0;
	}

	p {
		margin: 0;
	}
}

.is-scrolled {
	.pinned-image,
	.pinned-content {
		transform: translateX(0) !important;
		opacity: 1 !important;
	}
}


// -----------------------------------------------------------------------------
//! Blog Category Listing
// -----------------------------------------------------------------------------

.category-listing {
	max-width: 722px;
	margin: 0 auto 64px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	gap: 16px;
	flex-wrap: wrap;

	span,
	a {
		white-space: nowrap;
	}
}


// -----------------------------------------------------------------------------
//! Blog Post Listing
// -----------------------------------------------------------------------------

.blog-listing {
	padding: 80px 0 114px;
}

.blog-listing .container {
	max-width: 1212px + 64px;
}

.blog-posts {
	display: grid;
	grid-template-columns: 1fr;
	gap: 32px;
	max-width: 100%;
	margin: 0 auto;

	@include respond(480px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@include respond($bp-padding) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	article {
		position: relative;
		z-index: $z_index-level1 + 1;
		display: flex;
		flex-direction: column;
		gap: 32px;
		justify-content: flex-start;
		align-items: stretch;
	}

	.post-image {
		border-radius: 3px;
		overflow: hidden;

		a,
		img {
			display: block;
		}

		img {
			object-fit: cover;
			width: 100%;
			transform: scale(1);
			transition: transform 0.8s ease
		}

		&:hover img {
			transform: scale(1.1);
		}
	}

	h3 {
		position: relative;
		z-index: $z_index-level2;
		font-family: $font;
		font-size: 2.2rem;
		line-height: 1;
		letter-spacing: -0.02em;
		font-style: italic;
		text-transform: uppercase;
		margin: 0;

		a {
			color: inherit;
			text-decoration: none;

			&:hover {
				color: $color-gold;
			}
		}
	}

	p {
		margin: 0;
	}

	.post-cta {
		margin-top: auto;
	}
}

.blog-listing .pagination {
	margin-top: 64px;

	a[aria-label="previous page"] {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;

		svg {
			transform: scaleX(-1);
		}
	}

	a[aria-label="next page"] {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}
}