// -----------------------------------------------------------------------------
//! Pagination
// -----------------------------------------------------------------------------

.pagination {
	.pagination-list {
		margin: 0 auto;
		max-width: 1212px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 1px;
		list-style: none;
		padding: 0;
	}

	li {
		flex: 0 0 58px;
		padding: 0;
		margin: 0;
	}

	span,
	a {
		width: 58px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		background-color: #2d2d2e;
		font-weight: 500;
		font-size: 18px;
		line-height: calc(28/18);
		text-decoration: none;

		svg {
			opacity: 0.8;
			transition: opacity 0.4s ease;
		}

		&.prev {
			svg {
				transform: scaleX(-1);
			}
		}

		&:hover {
			svg {
				opacity: 1;
			}
		}

		&.is-current {
			background-color: $color-gold;
		}

		&.pagination-ellipsis {
			background-color: #fff;
			color: #2d2d2e;
		}
	}
}