.popup-overlay {
	position: fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	background: rgba(45, 45, 46, 0.6);
	z-index: $z_index-level6;
	display: none;
}


.popup-wrap {
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index: $z_index-level6 + 1;
	max-height: 100%;
	max-width: 100%;
	display: none;
	padding-top: 10vh;
	overflow: auto;
	padding-left: 16px;
	padding-right: 16px;
}

.popup {
	width: 100%;
	max-width: 600px;
	background-color: $color-blue;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	margin: 0 auto 20px auto;
	border-radius: 3px;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		z-index: $z_index-level1;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background: linear-gradient(0deg, rgba(65, 64, 66, 0.4), rgba(65, 64, 66, 0.4));
		background-blend-mode: multiply, normal;
	}
}

.popup-close {
	position: absolute;
	top: 24px;
	right: 24px;
	width: 32px;
	height: 32px;
	display: block;
	padding: 0;
	cursor: pointer;
	background: transparent;
	border: none;
	padding: 6px;
	z-index: $z_index-level1 + 2;
	svg {
		width: 20px;
		height: 20px;
		display: block;
	}
}

.popup-inner {
	position: relative;
	z-index: $z_index-level1 + 1;
	padding: 80px 32px;
	text-align: center;
	@include respond($bp-padding) {
		padding: 167px 75px;
	}
}

.popup-title {
	color: #fff;
	font-family: $font;
	font-size: 2.4rem;
	line-height: 1;
	text-transform: uppercase;
	font-style: italic;
	font-weight: 900;
	letter-spacing: -0.03em;
	margin: 0 0 43px 0;
	position: relative;
	@include respond($bp-padding) {
		font-size: 4.0rem;
	}
	&:after {
		content:"";
		max-width:130px;
		width: 100%;
		height: 3px;
		background: $color-gold;
		position: absolute;
		bottom: -29px;
		left: 50%;
		transform: translateX(-50%);
	}
}

.popup-text {
	color: #fff;
	margin: 0 0 40px 0;
	p,
	li {
		font-size: 1.6rem;
		line-height: calc(25/16);
	}
}