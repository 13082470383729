.videoBlock {
    padding-top: 40px;
    padding-bottom: 40px;
    background: linear-gradient(180deg, #414042 0%, #202020 100%);
    position: relative;
    margin-top: 67px;

    @include respond($md) {
        padding-top: 150px;
        padding-bottom: 150px;
        margin-top: 150px;
    }

    .videoBlock-container {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        overflow: hidden;

        iframe {
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            width: 100%;
            height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
            min-height: 60vh;
            // min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
            min-width: 100%;
        }
    }
}
