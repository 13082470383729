.imageGallery {
    // padding-top: 67px;
    // padding-bottom: 67px;

    // @include respond($md) {
    //     padding-top: 150px;
    //     padding-bottom: 150px;
    // }

    .imageGallery-text {
        margin-bottom: 80px;
        display: block;

        @include respond($md) {
            display: flex;
            justify-content: space-between;
        }

        h2 {
            max-width: 640px;
        }

        p {
            margin: 0;
        }

        h2,
        p {
            flex: 1;
        }
    }

    .imageGallery-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;

        @include respond($md) {
            grid-template-columns: 1fr 1fr;
        }

        @include respond($lg) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
