.community {
	overflow: hidden;
	padding: 80px 0;
	position: relative;
	@include respond($bp-padding) {
		padding: 145px 0 173px 0;
	}
	
	.container {
		max-width: 1368px;
	}
	&:before {
		content: "";
		position: absolute;
		width: 15px;
		height: 116px;
		right: 0;
		top: 0;
		background-image: url(../images/diagonal-dark.png);
		background-repeat: repeat-y;
		background-position: left top;
		background-size: 100% auto;
		@include respond($bp-padding) {
			width: 22px;
			height: 292px;
		}
		
	}
}

.community-grid {
	position: relative;
	display: grid;
	margin: 0 auto;
	gap: 16px;
	max-width: 648px; 
	grid-template-columns: 1fr 1fr;
	@include respond(560px) {
		gap: 24px;
		grid-template-columns: repeat(auto-fill, minmax(222px, 1fr) ) ;
	}
	@include respond(900px) {
		grid-template-columns: repeat(auto-fill, minmax(190px, 1fr) ) ;
		max-width: 100%;
	}	
	@include respond(1120px) {
		grid-template-columns: repeat(auto-fill, minmax(242px, 1fr) ) ;
	}
	
	.community-grid-image {
		position: relative;
		border-radius: 3px;
		padding-top: (448 / 624) * 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		&.lazyload {
			background-image: none !important;
		} 
	}
}

.community-outline {
	position: absolute;
	position: absolute;
	left: -25px;
	top: -25px;
	height: auto;
	max-width: 86vw;
	transform: translateX(80px);
	opacity: 0;
	transition: opacity 0.4s ease, transform 0.8s ease;
	@include respond($bp-outline, "down") {
		height: 45px;
		max-width: none;
		width: auto;
		top: 265px;
	}
	@include respond($bp-padding) {
		max-width: none;
		left: -73px;
		top: -73px;
	}
}


.supplement-outline {
	position: absolute;
	max-width: 90vw;
	bottom: -4px;
	right: -12px;
	@include respond($bp-outline, "down") {
		height: 45px;
		max-width: none;
		width: auto;
		left: 4px;
		right: auto;
		bottom: -3px;
	}
}


.is-scrolled {
	.community-outline {
		transform: translate(0);
		opacity: 1;
	}
}