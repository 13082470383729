.textBlock {
    &.isDark {
        background: linear-gradient(180deg, #414042 0%, #202020 100%);

        h2,
        h3,
        p {
            color: #ffffff;
        }

        .textBlock-container {
            padding-top: 67px;
            padding-bottom: 67px;

            @include respond($md) {
                padding-top: 150px;
                padding-bottom: 150px;
            }
        }
    }

    .textBlock-container {
        max-width: 870px;
        margin: 0 auto;
    }

    h2 {
        font-family: $font;
        font-size: 3.4rem;
        font-style: italic;
        font-weight: 800;
        text-transform: uppercase;
        margin: 0 0 17px;
    }

    h3 {
        font-family: $font;
        font-size: 1.8rem;
        font-style: italic;
        font-weight: 800;
        text-transform: uppercase;
        margin: 20px 0 15px;
    }
}

.textBlockSplit {
    &.isDark {
        background: linear-gradient(180deg, #414042 0%, #202020 100%);

        h2,
        h3,
        p {
            color: #ffffff;
        }

        .textBlock-container {
            padding-top: 67px;
            padding-bottom: 67px;

            @include respond($md) {
                padding-top: 150px;
                padding-bottom: 150px;
            }
        }
    }

    .textBlock-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;
        // padding-top: 67px;
        // padding-bottom: 67px;

        @include respond($md) {
            // padding-top: 150px;
            // padding-bottom: 150px;
            grid-template-columns: 1fr 1fr;
            gap: 60px;
        }
    }

    h2 {
        font-family: $font;
        font-size: 3.4rem;
        font-style: italic;
        font-weight: 800;
        text-transform: uppercase;
        margin: 0 0 17px;
    }

    h3 {
        font-family: $font;
        font-size: 1.8rem;
        font-style: italic;
        font-weight: 800;
        text-transform: uppercase;
        margin: 20px 0 15px;
    }
}
