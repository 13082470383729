.titleSplit {
	margin: 0 0 40px 0;

	@include respond($bp-mobile) {
		margin: 0 0 80px 0;
	}

	@include respond($bp-padding) {
		margin: 0 0 130px 0;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.titleSplit-left {
	transform: translateX(-40px);
	opacity: 0;
	transition: opacity 0.4s ease 0.4s, transform 0.4s ease 0.4s;

	@include respond($bp-padding) {
		flex: 1 1 640px;
		max-width: 640px;
	}
}


.titleSplit-right {
	transform: translateX(40px);
	opacity: 0;
	transition: opacity 0.4s ease 0.4s, transform 0.4s ease 0.4s;

	@include respond($bp-padding) {
		flex: 1 1 590px;
		padding: 0 0 0 32px;
		max-width: 590px;
	}

	&.padding-top {
		padding-top: 20px;

		@include respond($bp-padding) {
			padding-top: 85px;
		}
	}
}

.titleSplit-text {
	font-size: 1.6rem;
	line-height: calc(25/16);
	margin: 0;
}


// -----------------------------------------------------------------------------
//! Animations
// -----------------------------------------------------------------------------

.is-scrolled {

	.titleSplit-right,
	.titleSplit-left {
		transform: translate(0);
		opacity: 1;
	}

}