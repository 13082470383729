$bp-punches-lg: 700px;
$bp-gear-lg: 880px;
$bp-gear-sm: 365px;
$bp-benefits-stack: 640px;

.boxing {
	padding: 80px 0;
	@include respond($bp-padding) {
		padding: 147px 0 183px 0;
	}
}

.boxing-section-subtitle {
	max-width: 600px;
	margin: 0 0 60px 0;
	position: relative;
	&.less-margin {
		margin-bottom: 40px;
	}
	@include respond($bp-padding) {
		margin: 0 0 80px 0;
		&.less-margin {
			margin-bottom: 40px;
		}
	}
	@include respond($bp-mobile, "down") {
		&.less-margin {
			margin-bottom: 40px;
		}
	}
}

.boxing-outline {
	position: absolute;
	bottom: 20px;
	left: calc(100% - 70px);	
	max-width: 90vw;

	@include respond($bp-outline, "down") {
		height: 45px;
		max-width: none;
		width: auto;
		left: -10px;
		bottom: calc(100% - 225px);
		z-index: $z_index-level0;
	}
}

.boxing-title {
	font-size: 2.4rem;
	line-height: calc(32/24);
	letter-spacing: -0.02em;
	font-weight: 800;
	font-family: $font;
	font-style: italic;
	text-transform: uppercase;
	margin: 0 0 16px 0;
	@include respond($bp-padding) {
		font-size: 3.4rem;
		line-height: calc(38/24);
		margin-bottom: 32px;
		&.less-margin {
			margin-bottom: 25px;
		}
	}
}

.punches-grid {
	display: grid;
	gap: 16px;
	grid-template-columns: 1fr;
	margin: 0 0 40px 0;
	@include respond(370px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	@include respond($bp-punches-lg) {
		gap: 24px;
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
	@include respond($bp-padding) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
		margin: 0 0 80px 0;
	}
}

.punches-box {
	position: relative;
	cursor: pointer;
	background-color: $color-border;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	padding-top: (408/312) * 100%;
	transition: transform 0.8s ease;
	border-radius: 3px;
	overflow: hidden;
	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(0deg, rgba(65, 64, 66, 0.7) 0%, rgba(65, 64, 66, 0) 100%);
		background-blend-mode: multiply;
		height: 144px;	
	}
}

.punches-box-text {
	position: absolute;
	bottom: 0;
	left:0;
	right: 0;
	z-index: $z_index-level1 + 1;
	padding: 16px;
	color: #fff;
	font-size: 1.8rem;
	line-height: calc(24/18);
	font-weight: 800;
	font-style: italic;
	text-transform: uppercase;
	text-align: center;
	@include respond($bp-punches-lg) {
		padding: 28px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-align: left;
	}
	svg {
		
		@include respond($bp-punches-lg, "down") {
			display: block;	
			margin: 10px auto 0 auto;
		}
		flex-shrink: 0;
	}
}

.punches-box:hover {
	@include respond($bp-punches-lg) {
		transform: translateY(-20px);
	}
	svg * {
		fill: $color-gold;
	}
}

.boxing-gear {
	display: grid;
	margin: 0 0 40px 0;
	grid-template-columns: 1fr;	
	gap: 16px;
	@include respond($bp-gear-sm) {
		grid-template-columns: repeat(2, 1fr);			
	}
	@include respond($bp-punches-lg) {
		grid-template-columns: repeat(3, 1fr);	
		gap: 24px;
		margin: 0 0 86px 0;
	}
	@include respond($bp-gear-lg) {
		grid-template-columns: repeat(4, 1fr);	
	}
}

.boxing-gear-box {
	overflow: hidden;
	position: relative;
	border-radius: 3px;
	background-color: $color-border;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	padding-top: (408/647) * 100%;
	transition: transform 0.8s ease;
	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(0deg, rgba(65, 64, 66, 0.7) 0%, rgba(65, 64, 66, 0) 100%);
		background-blend-mode: multiply;
		height: 144px;	
	}
}

.boxing-gear-text {
	position: absolute;
	bottom: 0;
	left:0;
	right: 0;
	z-index: $z_index-level1 + 1;
	padding: 16px;
	color: #fff;
	text-align: left;
	
	@include respond($bp-punches-lg) {
		padding: 28px;
	}
}

.boxing-gear-box[data-target="modal-video"]:hover {
	@include respond($bp-punches-lg) {
		transform: translateY(-20px);
	}
	svg * {
		fill: $color-gold;
	}
}

.boxing-gear-text-title {
	font-size: 1.8rem;
	line-height: calc(24/18);
	font-weight: 800;
	font-style: italic;
	text-transform: uppercase;
	display: block;
}

.boxing-gear-text-text {
	margin: 9px 0 0 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 1.5rem;
	line-height: calc(24/15);
	letter-spacing: -0.02em;
	@include respond($bp-padding) {
		font-size: 1.6rem;
		line-height: 1.5;	
	}
	svg {
		flex-shrink: 0;
		margin-right: 13px;
	}
}

#boxing-gear-1 {
	@include respond($bp-gear-sm) {
		grid-column: 1 / span 2;
	}
	@include respond($bp-gear-lg) {
		grid-column: 1 / span 2;
		grid-row: 1 / span 2;
	}
}

#boxing-gear-2 {
	@include respond($bp-gear-sm) {
		grid-column: 1;
		grid-row: 2 / span 2;
	}
	@include respond($bp-punches-lg) {
		grid-column: 3;
		grid-row: 1 / span 2;
	}
	@include respond($bp-gear-lg) {
		grid-row: 1 / span 2;
	}
}

#boxing-gear-3 {
	@include respond($bp-gear-sm) {
		grid-column: 2;
	}
	@include respond($bp-punches-lg) {
		grid-column: 1;
	}
	@include respond($bp-gear-lg) {
		grid-column: 4;
		grid-row: 1;
	}
}

#boxing-gear-4{
	@include respond($bp-gear-sm) {
		grid-column: 2;
		grid-row: 3;
	}
	@include respond($bp-punches-lg) {
		grid-row: 2;
	}
	@include respond($bp-gear-lg) {
		grid-column: 4;
		grid-row: 2;
	}
}


.benefits-grid {
	display: grid;
	grid-template-columns: 1fr;
	gap: 24px;
	@include respond($bp-padding) {
		grid-template-columns: repeat(2, minmax(0, 1fr));	
	}
	
}

.benefits-grid-box {
	padding: 206px 24px 24px 24px;
	position: relative;
	border-radius: 3px;
	overflow: hidden;
	background: linear-gradient(180deg, #F5F5F6 0%, rgba(249, 250, 250, 0.78) 52.6%, rgba(253, 254, 254, 0) 100%);
	@include respond($bp-benefits-stack) {
		padding: 60px 45% 56px 50px;	
	}
	h3 {
		position: relative;		
		z-index: $z_index-level2;
		font-family: $font;
		font-size: 2.2rem;
		line-height: 1;
		letter-spacing: -0.02em;
		font-style: italic;
		text-transform: uppercase;
	}
	ul {
		position: relative;
		z-index: $z_index-level2;
		margin: 0;
		padding: 0;
		li {
			margin: 0 0 6px 0;
			padding: 0 0 0 30px;
			list-style: none;
			position: relative;
			font-size: 1.5rem;
			line-height: calc(25/16);
			@include respond($bp-benefits-stack) {
				font-size: 1.6rem;
				line-height: calc(25/16);	
			}
			&:after {
				content: "";
				position: absolute;
				left: 0;
				top: 6px;
				width: 15px;
				height: 12px;
				background-size: auto;
				background-repeat: no-repeat;
				background-position: left center;
				background-image: url(../svg/check-gold.svg);				
			}
		}
	}
	&:before {		
		content: "";
		position: absolute;
		z-index: $z_index-level1 + 1;
		height: 176px;
		right: 0;
		top: 0;		
		width: 100%;
		border-radius: 3px 0 0 3px;
		background-size: auto 150%;
		background-repeat: no-repeat;
		background-position: right top;
		@include respond($bp-benefits-stack) {
			background-size: auto 100%;
			background-position: right center;
			bottom: 0;
			width: 65%;
			border-radius: 3px;
			height: 100%;
		}
		@include respond(780px) {
			background-position: right center;
		}
		@include respond($bp-padding) {
			background-position: left center;
		}
		
		@include respond(1200px) {
			background-position: right center;
		}
	}
	&.lazyload:before {
		background-image: none;
	}
	&.lazyloaded:nth-child(1):before {
		background-image:url(../webp/benefits-1.webp);
	}
	&.lazyloaded:nth-child(2):before {
		background-image:url(../webp/benefits-2.webp);	
	}
	&:after {
		content: "";
		position: absolute;
		z-index: $z_index-level1;
		bottom: 0;
		left: 0;
		border-radius: 3px;
		width: 251px;
		height: 144px;
		background: linear-gradient(70.74deg, #F5F5F6 0%, rgba(245, 245, 246, 0) 28.57%);	
	}
}

.no-webp {
	.benefits-grid-box {
		&.lazyloaded:nth-child(1):before {
			background-image:url(../images/benefits-1.png);
		}
		&.lazyloaded:nth-child(2):before {
			background-image:url(../images/benefits-2.png);	
		}
	}
}
