.modular-content {
    // section.altblock {
    //     margin-top: 0;
    // }

    // section:first-child {
    //     margin-top: 0;
    // }

    section.start {
        padding-top: 0;
    }

    section {
        margin-top: 67px;

        @include respond($md) {
            margin-top: 150px;
        }
    }
    
    section.textBlock.isDark:first-child {
        margin-top: 0;
    }
    
    
    section.isDark + section.questions,
    section.isDark + section.isDark {
        margin-top: 0;
    }
}


.formBlock.is-stacked .form-container {
    display: block;
    .cf-intro-text {
        max-width: 600px;
        margin: 0 auto;
    }
    .cf-form {
        max-width: 600px;
        padding: 0;
        margin-top: 24px;
    }
}

.formBlock {
    &.isDark {
        background: linear-gradient(180deg, #414042 0%, #202020 100%);
    
        h2,
        h3,
        p,
        label.freeform-label,
        input.freeform-input {
            color: #ffffff;
        }
    
        .form-container {
            padding-top: 67px;
            padding-bottom: 67px;
    
            @include respond($md) {
                padding-top: 150px;
                padding-bottom: 150px;
            }
        }
    }
}

.altblock {
    &.isDark {
        background: linear-gradient(180deg, #414042 0%, #202020 100%);
        margin-bottom: 0;
        h2,
        h3,
        p{
            color: #ffffff;
        }
    
        .container {
            padding-top: 67px;
            padding-bottom: 67px;
    
            @include respond($md) {
                padding-top: 150px;
                padding-bottom: 150px;
            }
        }
    }
}


