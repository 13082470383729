$bp-contact-stack: 768px;

.contactForm {
	position: relative;
	padding: 80px 0;

	@include respond($bp-padding) {
		padding: 97px 0 150px 0;
	}

	&:before {
		content: "";
		position: absolute;
		width: 15px;
		height: 116px;
		left: 0;
		bottom: 0;
		background-image: url(../images/diagonal-dark.png);
		background-repeat: repeat-y;
		background-position: left top;
		background-size: 100% auto;

		@include respond($bp-contact-stack) {
			width: 22px;
			height: 292px;
		}

	}

	&.centered {
		padding-bottom: 80px;

		.container {

			text-align: center;
			// flex-direction: column;
			display: block;
		}

		.cf-text {
			padding-right: 0;
			max-width: 700px;
			margin: 0 auto;

			a {
				text-decoration: none;
			}
		}
	}
}

.contactForm .container {
	@include respond($bp-contact-stack) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		max-width: 1270px;
	}
}

// .page-personal-training .contactForm .container {
// 	@include respond($bp-contact-stack) {
// 		justify-content: center;

// 		#get-in-touch {
// 			max-width: 530px;
// 		}
// 	}
// }

.cf-text,
.cf-form {
	@include respond($bp-contact-stack) {
		flex: 1 1 474px;
		max-width: 474px;
	}
}

.cf-text {
	@include respond($bp-contact-stack) {
		padding-right: 24px;
	}
}

.cf-form {
	padding: 48px 0 0 0;

	@include respond($bp-contact-stack) {
		padding: 0 0 0 24px;
	}

	&.wl-form {
		padding: 0;
		max-width: 100%;

		.rs-lead-add-wrap .field-logo {
			display: none !important;
		}

	}
}

.cf-text-title {
	font-family: $font;
	line-height: 1;
	font-size: 2.2rem;
	font-weight: 800;
	font-style: italic;
	text-transform: uppercase;
	margin: 0 0 24px 0;
	position: relative;
	opacity: 0;
	transform: translateX(-80px);
	transition: opacity 0.4s ease, transform 0.8s ease;

	@include respond($bp-padding) {
		font-size: 3.4rem;
		margin: 0 0 32px 0;
	}
}

.cf-text-text {
	opacity: 0;
	transform: translateX(-80px);
	transition: opacity 0.4s ease 0.2s, transform 0.8s ease 0.2s;
	margin: 0 0 24px 0;

	@include respond($bp-padding) {
		margin: 0 0 37px 0;
	}
}

.cf-text-locations {
	opacity: 0;
	transform: translateX(-80px);
	transition: opacity 0.4s ease 0.4s, transform 0.8s ease 0.4s;
	display: grid;
	gap: 24px;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.is-scrolled {

	.cf-text-text,
	.cf-text-title,
	.cf-text-locations {
		opacity: 1;
		transform: translateX(0);
	}
}



.cf-location {
	min-width: 200px;
}


.cf-location-title {
	letter-spacing: -0.02em;
	text-transform: uppercase;
	font-weight: 800;
	font-style: italic;
	font-size: 1.6rem;
	line-height: calc(18/16);
	text-transform: uppercase;
	font-family: $font;
	margin: 0 0 10px 0;

	@include respond($bp-padding) {
		font-size: 1.8rem;
		line-height: calc(20/18);
	}
}

.cf-location-text {
	margin: 0 0 6px 0;
	font-size: 1.4rem;
	line-height: calc(23/14);

	@include respond($bp-padding) {
		font-size: 1.6rem;
		line-height: calc(25/16);
		margin: 0 0 9px 0;
	}
}

.cf-location-phone {
	text-decoration: none;
	font-weight: 700;
	font-size: 1.4rem;
	line-height: calc(23/14);
	color: $color-body;

	@include respond($bp-padding) {
		font-size: 1.6rem;
		line-height: calc(25/16);
	}

	&:hover,
	&:focus {
		color: $color-gold;
	}
}


// -----------------------------------------------------------------------------
//! Assorted Styles
// -----------------------------------------------------------------------------

.training-intro-bg {
	background: linear-gradient(180deg, rgba(230, 231, 232, 0) 0%, rgba(230, 231, 232, 0.4) 100%);

	.split {
		@include respond($bp-padding) {
			padding-bottom: 200px;
		}
	}

	.contactForm {
		padding-top: 0;
	}

	.cf-location-phone {
		margin-top: 14px;
		display: inline-block;
	}

	.cf-location-email {
		margin: 0;

		a {
			text-decoration: none;
			color: $color-body;

			&:hover {
				color: $color-gold;
				text-decoration: underline;
			}
		}
	}
}

.cf-location-text-aside {
	display: block;
	margin: 14px 0 0 0;
	font-size: 1.3rem;
	line-height: calc(22/13);
}

.page-personal-training {
	.cf-text-locations {
		margin-top: 30px;

		@include respond($bp-mobile, "down") {
			grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));

			.cf-location {
				min-width: 140px;
			}
		}
	}

	.community .button-row {
		margin-top: 44px;
	}
}