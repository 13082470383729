$bp-split-stack: 768px;

.split {
	padding: 80px 0 190px 0;
	position: relative;

	@include respond($bp-padding) {
		padding: 140px 0 240px 0;
	}

	&:before {
		content: "";
		position: absolute;
		width: 15px;
		height: 116px;
		left: 0;
		top: 368px;
		background-image: url(../images/diagonal-dark.png);
		background-repeat: repeat-y;
		background-position: left top;
		background-size: 100% auto;

		@include respond(480px) {
			top: 480px;
		}

		@include respond($bp-split-stack) {
			top: auto;
			width: 22px;
			height: 212px;
			bottom: 190px;
		}

		@include respond($bp-padding) {
			bottom: 240px;
		}
	}
}

.page-home .split {
	padding: 80px 0 80px 0;
	position: relative;

	@include respond($bp-padding) {
		padding: 140px 0 160px 0;
	}
}

.page-home .split+.split {
	padding-top: 0;

	@include respond($bp-padding) {
		padding-bottom: 132px;
	}

	.split-text {
		padding: 75px 0 0 0;
		position: relative;

		@include respond($bp-split-stack) {
			padding: 0 32px;
			min-width: 330px;
			max-width: 706px;
			flex: 1 1 calc(100% - 530px);
		}

		@include respond(1240px) {
			padding: 0 96px 0 14px;
		}
	}

	@include respond($bp-split-stack, "down") {
		.btn {
			margin-bottom: 40px;
		}
	}

	.nubieCTA-text-cta {
		margin-top: 40px;
	}

	.split-text-outline {
		left: -20px;
		top: 40px;

		@include respond($bp-split-stack) {
			top: -200px;
			left: -20px;
		}
	}
}

.page-home .split:before {
	display: none;
}

.split-aside {
	position: absolute;
	right: 0;
	bottom: 32px;
	height: 160px;
	opacity: 0;
	transform: translateX(40px);
	transition: opacity 0.4s ease, transform 0.8s ease;
	max-width: 216px;

	@include respond($bp-padding) {
		bottom: 46px;
		height: auto;
	}
}

.split .container {
	max-width: 1300px;
	position: relative;
	padding: 0 32px;

	@include respond($bp-split-stack) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.split-image {
	flex: 1 1 530px;
	max-width: 530px;
	position: relative;

	img {
		display: block;
		border-radius: 3px;
	}


	&:before {
		content: "";
		position: absolute;
		width: 78px;
		height: 36px;
		background-image: url(../svg/xx.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		top: 44%;
		left: -24px;
		z-index: $z_index-level1 + 1;

		@include respond($bp-split-stack) {
			width: 122px;
			height: 56px;
		}

		@include respond($bp-padding) {
			left: -40px;
			top: 54%;
		}

	}

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 66%;
		background: linear-gradient(360deg, rgba(45, 45, 46, 0.8) 0%, rgba(35, 59, 95, 0) 104.66%);
		background-blend-mode: multiply;
		z-index: $z_index-level1;
		border-radius: 0 0 3px 3px;
	}

	&.no-x:before {
		display: none;
	}
}

.boxing .split {
	padding-bottom: 0;

	&:before {
		left: auto;
		top: auto;
		bottom: 0;
		right: 0;

		@include respond(430px) {
			height: 292px;
		}
	}

	.split-image {
		flex: 1 1 480px;
		max-width: 480px;

		img {
			@include respond(400px, "down") {
				max-width: 90%;
			}
		}

		&:before {
			display: none;
		}

		&:after {
			display: none;
		}
	}
}

.fit .split-image {
	img {
		@include respond(400px, "down") {
			max-width: 90%;
			margin-left: 10%;
		}
	}
}

.split-image-text {
	z-index: $z_index-level1 + 2;
	position: absolute;
	left: 25px;
	bottom: 37px;

	@include respond($bp-split-stack, "down") {
		svg {
			height: 72px;
			width: auto;
		}
	}

	@include respond($bp-padding) {
		left: 66px;
		bottom: 56px;
	}

	.split-image-text-hashtag {
		color: #fff;
		font-size: 1.4rem;
		line-height: calc(14/12);
		font-weight: 700;
		margin: 13px 0 0 0;

		@include respond($bp-split-stack) {
			font-size: 1.8rem;
			line-height: calc(20/18);
			margin: 22px 0 0 0;
		}
	}
}



.split-text {
	padding: 75px 0 0 0;
	position: relative;

	@include respond($bp-split-stack) {
		padding: 0 32px;
		min-width: 330px;
		max-width: 706px;
		flex: 1 1 calc(100% - 530px);
	}

	@include respond(1240px) {
		padding: 0 96px 0 160px;
	}
}

.page-nubies .split-text {
	@include respond($bp-split-stack, "down") {
		padding: 40px 0 0 0;
	}
}

.split-text-outline {
	position: absolute;
	left: 64px;
	top: 43px;
	height: auto;
	max-width: 86vw;
	transform: translateX(80px);
	opacity: 0;
	transition: opacity 0.4s ease, transform 0.8s ease;

	@include respond($bp-outline, "down") {
		height: 45px;
		max-width: none;
		width: auto;
		left: 0;
	}

	@include respond($bp-split-stack) {
		top: -32px;
	}

	@include respond($bp-padding) {
		top: -82px;
		left: 107px;
	}

	&.personalized-text {
		height: auto;
		max-width: 86vw;

		@include respond($bp-split-stack) {
			height: 106px;
			max-width: none;
		}
	}
}


.split-text .section-title {
	margin: 0 0 24px 0;
	transform: translateY(40px);
	opacity: 0;
	transition: opacity 0.4s ease, transform 0.8s ease;

	@include respond($bp-split-stack) {
		margin: 0 0 30px 0;
	}
}

.split-text .btn {
	transform: translateY(40px);
	opacity: 0;
	transition: opacity 0.4s ease 0.4s, transform 0.8s ease 0.4s;
}


.split-text-text {
	font-size: 1.6rem;
	line-height: calc(25/16);
	margin: 0 0 36px 0;
	position: relative;
	transform: translateY(40px);
	opacity: 0;
	transition: opacity 0.4s ease 0.2s, transform 0.8s ease 0.2s;

	@include respond($bp-split-stack) {
		margin: 0 0 43px 0;
	}
}

.split-stats {
	position: absolute;
	right: 24px;
	top: 50%;
	transform: translate(24px, -50%);
	width: 92px;

	@include respond(430px) {
		width: 155px;
		right: 0;
		transform: translate(16px, -50%);
	}

	@include respond(1240px) {
		transform: translate(80px, -50%);
	}
}

.split-stats-box {
	padding: 8px;
	border-radius: 3px;
	background: #fff;
	box-shadow: 0px 20px 30px rgba(65, 64, 66, 0.1);
	margin: 5px 0;
	opacity: 0;
	transform: translateY(80px);
	transition: transform 0.8s ease, opacity 0.8s ease;

	@include respond(430px) {
		padding: 18px;
	}

	&.is-gold {
		background-color: $color-gold;
		color: #fff;
	}

	img {
		margin: 0 auto;
	}

	&:nth-child(2) {
		transition-delay: 0.1s;
	}

	&:nth-child(3) {
		transition-delay: 0.2s;
	}

	&:nth-child(4) {
		transition-delay: 0.3s;
	}
}

.split-stats-title {
	font-size: 0.9rem;
	line-height: 1.1;
	margin: 0 0 6px 0;
	text-transform: uppercase;
	font-weight: 700;

	@include respond($bp-padding) {
		font-size: 1.0rem;
		font-weight: 800;
	}
}

.split-stats-num {
	font-weight: 700;
	font-size: 2rem;
	line-height: 1;
	font-style: italic;
	text-transform: uppercase;

	@include respond(430px) {
		font-size: 2.8rem;
		font-weight: 800;
	}

	span {
		font-weight: normal;
		text-transform: none;
		font-size: 1.3rem;
		line-height: calc(20/13);
		font-style: normal;

		@include respond(430px) {
			font-size: 1.6rem;
			line-height: 1.5;
		}
	}
}

.personalized-outline {
	position: absolute;
	left: 24px;
	top: calc(100% + 16px);
	height: auto;
	max-width: 90vw;
	transform: translateX(80px);
	opacity: 0;
	transition: opacity 0.4s ease, transform 0.8s ease;

	@include respond($bp-outline, "down") {
		height: 45px;
		max-width: none;
		width: auto;
		left: 0;
	}

	@include respond($bp-split-stack) {
		top: calc(100% - 8px);
	}

	@include respond($bp-padding) {
		left: -123px;
	}
}

.page-personal-training {

	.split {
		@include respond($bp-mobile, "down") {
			padding-bottom: 80px;
		}
	}

	.split:before {
		display: none;
	}

	.split-text {
		order: 2;

		@include respond($bp-split-stack, "down") {
			padding: 40px 0 0 0;
		}
	}

	.split-image {
		position: relative;
		z-index: $z_index-level1 + 1;
		order: 1;

		&:before {
			display: none;
		}
	}

	.split-image:after {
		display: none;
	}
}



#split-image-slider {
	overflow: hidden;
	position: relative;
	border-radius: 3px;
	padding-top: (686 / 559) * 100%;

	&:before {
		display: none;
	}

	&:after {
		content: "";
		position: absolute;
		z-index: $z_index-level1 + 1;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(0deg, rgba(65, 64, 66, 0.7) 0%, rgba(65, 64, 66, 0) 100%);
		background-blend-mode: multiply;
		height: 80px;

		@include respond($bp-locations-stack) {
			height: 144px;
		}
	}

	.swiper-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: $z_index-level1;
	}

	.swiper-pagination {
		z-index: $z_index-level1 + 2;
	}

	.location-button-prev,
	.location-button-next {
		z-index: $z_index-level1 + 3;
	}

	&:hover {

		.location-button-prev,
		.location-button-next {
			opacity: 1;
		}
	}
}

// -----------------------------------------------------------------------------
//! Animations
// -----------------------------------------------------------------------------

.is-scrolled {

	&.split-aside,
	.split-stats-box,
	.personalized-outline,
	.split-text-outline,
	.split-text .section-title,
	.split-text-text,
	.split-text .btn {
		transform: translate(0);
		opacity: 1;
	}
}