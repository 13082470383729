.start {
	position: relative;
	padding: 80px 0;
	@include respond($bp-padding) {
		padding: 147px 0 200px 0;
	}
}

.page-personal-training .start {
	@include respond($bp-padding) {
		padding-bottom: 147px;
	}
}

.start-intro {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 0 0 48px 0;
	position: relative;
	@include respond($bp-padding) {
		margin: 0 0 100px 0;
	}
}

.start-intro-right {
	flex: 1 1 330px;
	max-width: 330px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: 32px 0 0 0;
	@include respond($bp-mobile) {
		justify-content: flex-end;	
	}
	a {
		margin: 0 0 18px 0;
	}
	a:first-of-type {
		margin: 0 18px 18px 0;
	}
}

.start-intro-left {
	flex: 1 1 calc(100% - 330px);
	max-width: 664px;
	padding: 0 64px 0 0;
	min-width: 500px;
	p:last-of-type {
		margin-bottom: 0;
	}
	@include respond(600px, "down") {
		min-width: 0;
		padding: 0;
		flex: 1 1 auto;
	}
}

.getstarted-outline {
	position: absolute;
	right: -34px;
	bottom: -4px;
	width: 1030px;
	max-width: 90vw;
	z-index: -1;
	@include respond($bp-outline, "down") {
		height: 45px;
		left: 8px;
		right: auto;
		max-width: none;
		width: auto;
	}
}

.format-outline {
	position: absolute;
	right: -80px;
	top: 115%;
	max-width: 90vw;
	
	@include respond($bp-outline, "down") {
		height: 45px;
		left: 48px;
		right: auto;
		max-width: none;
		width: auto;
	}
}

