.locale {
	
	background: linear-gradient(180deg, #414042 0%, #202020 100%);
	
	@include respond(800px) {
		display: flex;
		align-items: stretch;
		justify-content: center;
	}
}

.locale-map {
	flex: 1 1 688px;
	max-width: 688px;
	min-width: 375px;
	background-color: transparent;
	background-image: url(../webp/locale-map.webp);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	margin: 58px 0;
	@include respond(800px, "down") {
		margin: 40px 0 0 0;
		width: 100%;
		max-width: none;
		//padding-top: (500 / 688) * 100%;
		aspect-ratio: 688 / 500;	
	}
	
}

.locale-text {
	flex: 1 1 688px;
	max-width: 688px;
	padding: 40px 20px;
	@include respond(400px) {
		padding: 40px;
	}
	
	
	@include respond(1200px) {
		padding: 80px 72px 56px 72px;
	}
}

.locale-text-inner {
	max-width: 545px;
	
	h2, p, li {
		color: #fff;
	}
}

.locale-text-split {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	flex-direction: column;
	gap: 32px;
	@include respond(1024px) {
		flex-direction: row;
	}
}

.locale-text-split-address {
	
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 24px;
	@include respond(1024px) {
		flex-direction: column;
		flex: 1 1 180px;
		max-width: 180px;
		flex-wrap: nowrap;
	}
	address {
		font-style: normal;
		color: #fff;
		line-height: 24px;
		font-size: 15px;
		font-family: $font;
		a {
			color: #fff;
			font-weight: 700;
			margin-top:5px;
			display: block;
			text-decoration: none;
		}
	}
}

.locale-text-split-list {
	ul {
		margin: 0;
		padding: 0;
		li {
			margin: 0 0 5px 0;
			padding: 0 0 0 30px;
			list-style: none;
			position: relative;
			font-size: 1.5rem;
			line-height: calc(25/15);
			&:last-child {
				margin: 0;
			}
			&:after {
				content: "";
				position: absolute;
				left: 0;
				top: 6px;
				width: 15px;
				height: 12px;
				background-size: auto;
				background-repeat: no-repeat;
				background-position: left center;
				background-image: url(../svg/check-gold.svg);
				
			}
		}
	}
}