// -----------------------------------------------------------------------------
//! Fonts
// -----------------------------------------------------------------------------

$font: "Gotham A", "Gotham B", Arial, Helvetica, Sans-serif;
$font-accent: "Sharp Grotesk", Arial, Helvetica, Sans-serif;

// -----------------------------------------------------------------------------
//! Colors
// -----------------------------------------------------------------------------

$color-body: #414042;
$color-gold: #b79756;
$color-blue: #233b5f;
$color-dark: $color-body;
$color-border: #e6e7e8;
$color-gray: #f5f5f6;

// -----------------------------------------------------------------------------
//! Z-indexes
// -----------------------------------------------------------------------------

$z_index-level6: 120;
$z_index-level5: 100;
$z_index-level4: 75;
$z_index-level3: 50;
$z_index-level2: 25;
$z_index-level1: 1;
$z_index-level0: -1;

// -----------------------------------------------------------------------------
//! Breakpoints
// -----------------------------------------------------------------------------

$bp-padding: 1024px;
$bp-outline: 400px;
$bp-mobile: 480px;

$sm: 576px;
$md: 768px;
$lg: 992px;
