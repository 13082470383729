.dualImages {
    // padding-top: 67px;
    // padding-bottom: 67px;

    // @include respond($md) {
    //     padding-top: 150px;
    //     padding-bottom: 150px;
    //     grid-template-columns: 1fr 1fr;
    // }

    .dualImages-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: 60px;

        @include respond($md) {
            grid-template-columns: 1fr 1fr;
        }

        .image {
            margin-bottom: 40px;
        }

        h2 {
            font-family: $font;
            font-size: 3.4rem;
            font-style: italic;
            font-weight: 800;
            text-transform: uppercase;
            margin: 0 0 17px;
        }

        h3 {
            font-family: $font;
            font-size: 1.8rem;
            font-style: italic;
            font-weight: 800;
            text-transform: uppercase;
            margin: 20px 0 15px;
        }

        .text {
            &.centered {
                text-align: center;
            }
        }
    }

    .displayNone {
        display: none;
    }
}

.threeImages {
    // padding-bottom: 67px;

    // @include respond($md) {
    //     padding-bottom: 150px;
    // }

    .threeImages-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: 60px;

        @include respond($md) {
            grid-template-columns: 1fr 1fr;
        }

        @include respond($lg) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .image {
            margin-bottom: 40px;
        }

        h2 {
            font-family: $font;
            font-size: 3.4rem;
            font-style: italic;
            font-weight: 800;
            text-transform: uppercase;
            margin: 0 0 17px;
        }

        h3 {
            font-family: $font;
            font-size: 1.8rem;
            font-style: italic;
            font-weight: 800;
            text-transform: uppercase;
            margin: 20px 0 15px;
        }

        .text {
            &.centered {
                text-align: center;
            }
        }
    }

    .displayNone {
        display: none;
    }
}
