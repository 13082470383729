$bp-btn-sm: 768px;

button,
.btn {
	cursor: pointer;
	font-family: $font;
}

.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-family: $font;
	font-weight: bold;
	text-transform: uppercase;
	border-radius: 2px;
	text-decoration: none;
	letter-spacing: -0.02em;
	font-size: 1.1rem;
	line-height: calc(12/11);
	padding: 16px 24px;
	position: relative;
	overflow: hidden;
	transition: color 0.4s ease 0.2s;

	@include respond($bp-btn-sm) {
		line-height: calc(14/13);
		padding: 20px 28px;
		font-size: 1.3rem;
	}

	span {
		position: relative;
		z-index: $z_index-level1 + 2;
		display: block;
	}

	&:after {
		content: "";
		position: absolute;
		z-index: $z_index-level1 + 1;
		top: 0;
		left: 0;
		bottom: 0;
		width: 0%;
		background-color: $color-gold;
		transition: width 0.4s cubic-bezier(0.42, 0, 1.0, 0.4);
	}

	&:hover:after,
	&:focus:after {
		width: 100%;
	}
}

.btn-white {
	background-color: #fff;
	color: $color-body;

	&:hover,
	&:focus {
		color: #fff;
	}
}

.btn-blue {
	background-color: $color-blue;
	color: #fff;

	&:hover,
	&:focus {
		color: #fff;
	}
}

.btn-gold {
	background-color: $color-gold;
	color: #fff;

	&:hover,
	&:focus {
		color: #fff;
	}
}

.btn-pill {
	border-radius: 100px;
	padding: 10px 14px;
	font-size: 1.3rem;
	line-height: calc(15/13);
}

.btn-nohover {
	cursor: auto;

	&:after {
		display: none;
	}
}

.button-row {
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	flex-wrap: wrap;

	.btn {
		margin: 0 16px 16px 0;

		@include respond($bp-btn-sm) {
			margin: 0 18px 18px 0;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}
}

.arrow-link {
	display: flex;
	align-items: center;
	justify-content: space-between;
	letter-spacing: -0.02em;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 1.3rem;
	line-height: calc(14/13);
	text-decoration: none;
	color: $color-body;

	@include respond($bp-padding) {
		font-size: 1.5rem;
		line-height: calc(17/15);
	}

	.arrow-link-title {
		font-weight: 800;
		font-size: 2.2rem;
		line-height: 1;
		margin: 0 0 12px 0;
		font-style: italic;

		@include respond($bp-padding) {
			font-size: 2.8rem;
			margin: 0 0 15px 0;
		}
	}

	svg {
		opacity: 0.8;
		width: 20px;
		height: 20px;

		@include respond($bp-padding) {
			width: 25px;
			height: 25px;
		}
	}

	&:hover,
	&:focus {
		svg .arrow {
			fill: $color-gold !important;
			stroke: $color-gold !important;
		}
	}

	&.margin-bottom {
		margin-bottom: 16px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}