$bp-quotes-stack: 768px;

.quotes {
	padding: 230px 0 70px 0;
	background-image: url(../webp/quote-bg.webp);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 100%;	
	position: relative;
	&.lazyload {
		background: none;
	}
	@include respond($bp-quotes-stack) {
		padding: 122px 0 135px 0;
	}
	&:after {
		content: "";
		position: absolute;
		right: 0;
		bottom: 0;
		transform: translate(-130px, 50%);
		width: 122px;
		height: 56px;
		background-image: url(../svg/xx.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		@include respond($bp-quotes-stack, "down") {
			width: 78px;
			height: 36px;
			transform: translate(-34px, 50%);
		}
	}
	&.top-after:after {
		top: 0;
		bottom: auto;
		transform: translate(-130px, -50%);
		@include respond($bp-quotes-stack, "down") {
			transform: translate(0, -50%);
			right: auto;
			left: 32px;
		}
	}
}

.no-touchevents .quotes{
	background-attachment: fixed;
}

.page-what-is-nuboxx .quotes:after {
	display: none;
}

.page-nubies .quotes:after {
	display: none;
}

.page-covid-19 .quotes:after {
	display: none;
}

.page-classes .quotes:after {
	display: none;
}

.page-personal-training .quotes:after {
	display: none;
}

.page-private-events .quotes:after {
	display: none;
}

.no-webp .quotes {
	background-image: url(../images/quote-bg.jpg);
	&.lazyload {
		background: none;
	}
}

.quotes-inner {
	max-width: 1056px;
	margin: 0 auto;
	padding: 0 32px 0 0;
	@include respond($bp-quotes-stack) {
		position: relative;
		padding: 0 32px 0 328px;
		min-height: 400px;
	}
}

.quotes-image {
	position: absolute;
	right: calc(100% - 348px);
	top: -22px;
	z-index: $z_index-level1 + 1;
	width: 615px;
	height: 540px;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url(../webp/polaroid-2x.webp);
	@include respond($bp-quotes-stack, "down") {
		right: -32px;
		top: 10px;
		width: 302px;
		height: 265px;
	}
	&.lazyload {
		background-image: none;
	}
}

.no-webp .quotes-image {
	background-image: url(../images/polaroid-2x.png);
	&.lazyload {
		background-image: none;
	}
}

.quotes-slider {
	position: relative;
	z-index: $z_index-level1;
}

.quote {
	overflow: hidden;
}

.quote-attr {
	font-family: $font;
	font-weight: 800;
	font-size: 1.6rem;
	font-style: italic;
	color: $color-gold;
	line-height: calc(16/16);		
	letter-spacing: -0.02em;
	text-transform: uppercase;
	margin: 0 0 51px 0;
	position: relative;
	padding: 0 0 0 32px;
	@include respond($bp-quotes-stack) {
		padding: 0 0 0 52px;
	}
	@include respond($bp-padding) {
		margin: 0 0 53px 0;	
		font-size: 1.8rem;
		line-height: calc(20/18);
	}
	&:before {
		content: "";
		position: absolute;
		top: calc(100% + 21px);
		height: 3px;
		left: -74px;
		width: 185px;
		background: $color-gold;
	}
}

.quote-text {
	color:#fff;
	font-family: $font-accent;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 3.4rem;
	line-height: calc(31/34);
	margin: 0 0 25px 32px;
	position: relative;
	
	span {
		position: absolute;
		top: 0;
		right: 100%;
	}
	@include respond($bp-quotes-stack) {
		margin: 0 0 25px 52px;
	}
	@include respond($bp-padding) {
		font-size: 6rem;
		line-height: calc(55/60);
		margin: 0 0 50px 52px;
	}	
}

.quote .btn {
	margin-left: 32px;
	@include respond($bp-quotes-stack) {
		margin-left: 52px;
	}
}