.questions {
	background-color: $color-blue;
	background-attachment: fixed;
	background-size: cover;
	background-position: left bottom;
	padding: 80px 0;
	position: relative;
	@include respond($bp-padding) {
		padding: 152px 0 172px 0;
	}
	&.lazyloaded {
		background-image: url(../images/questions-bg.jpg);
	}
}

.questions .container {
	@include respond($bp-padding) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		max-width: 1164px;
	}
}

.questions-content {
	max-width: 764px;
	margin: 32px 0 0 0;
	@include respond($bp-padding) {
		margin: 0 0 0 64px;
	}
}

.questions {
	
	.section-title {
		color: #fff;
	}
	.faq-title {
		flex-shrink: 0;
		color: #fff;
		&:before {
			display: none;
		}
		svg .outer {
			fill: #fff;
		}
	}
	.faq-title.is-active svg .outer, .faq-title:focus svg .outer, .faq-title:hover svg .outer {
		fill: $color-gold;
	}
	.faq-text * {
		color: #fff;
	}
}


.faq-outline {
	position: absolute;
	bottom: -4px;
	right: -9px;	
	max-width: 90vw;

	@include respond($bp-outline, "down") {
		height: 45px;
		max-width: none;
		width: auto;
	}
}