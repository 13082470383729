// -----------------------------------------------------------------------------
//! GENERAL
// -----------------------------------------------------------------------------

html {
	height: 100%;
	background:#fff;
	overflow-x: hidden;
}

body {
	position: relative;
	min-height: 100%;
	overflow-x: hidden;
}

.container {
	max-width: 1384px;
	margin: 0 auto;
	padding: 0 32px;	
}
