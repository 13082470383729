$bp-classes-lg: 1200px;
$bp-classes-md: 900px;
$bp-classes-stack: 768px;
$bp-classes-sm: 420px;

.classes {
	padding:  80px 0;
	position: relative;
	@include respond($bp-padding) {
		padding: 133px 0 111px 0;	
	}
}

.classes-panels-wrap {
	position: relative;
	@include respond($bp-classes-stack) {
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
	}
}

.classes-text-outline {
	position: absolute;
	z-index: $z_index-level1;
	bottom: calc(100% - 10px);
	right: -12px;
	max-width: 85vw;
	height: auto;
	transform: translateX(80px);
	opacity: 0;
	transition: opacity 0.4s ease, transform 0.8s ease;
	@include respond($bp-outline, "down") {
		height: 45px;
		max-width: none;
		width: auto;
	}
	@include respond($bp-classes-stack) {
		bottom: calc(100% - 30px);	
	}
}


.classes-nav {
	position: relative;
	z-index: $z_index-level1 + 2;
	padding: 0 0 0 15px;
	background-image: url(../images/diagonal-dark.png);
	background-size: 15px auto;
	background-repeat: repeat-y;
	background-position: top left;
	@include respond($bp-classes-stack) {
		width: 40%;
		flex: 0 0 40%;
		padding: 0 0 0 22px;
		background-size: 22px auto;
	}
	@include respond($bp-classes-lg) {
		width: 28%;
		flex: 0 0 28%;
	}
}

.classes-nav-tabs {
	background: #fff;
	@include respond($bp-classes-stack, "down") {
		display: flex;
		align-items: flex-start;
		justify-content: center;
	}
}

.classes-nav-tab {
	position: relative;
	width: 25%;
	flex: 0 0 25%;
	background: transparent;
	border-top: 1px solid $color-border;	
	padding: 18px 8px;
	
	@include respond($bp-classes-stack){
		padding: 20px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;		
	}
	@include respond($bp-classes-md) {
		padding: 20px 20px 20px 40px;
	}
}

.classes-nav-tab-image {
	position: relative;
	display: block;
	width: 65px;
	margin: 0 auto 10px auto;
	img {
		display: block;
		border-radius: 5px;
		position: relative;
		z-index: $z_index-level1 + 1;
	}
	
	@include respond($bp-classes-stack){
		width: 100px;
		flex: 0 0 100px;
		margin: 0 15px 0 0;
	}	
	@include respond($bp-classes-md) {
		margin: 0 27px 0 0;	
	}
	&:before {
		content: "";
		transition: border 0.4s ease;
		border: 2px solid transparent;
		z-index: $z_index-level1;
		position: absolute;
		border-radius: 8px;
		left: -4px;
		top: -4px;
		bottom: -4px;
		right: -4px;
		
	}
}

.classes-nav-tab-text {
	transition: color 0.4s ease;
	flex: 1 1 128px;
	max-width: 128px;
	text-align: left;
	font-weight: bold;
	font-size: 1rem;
	line-height: calc(12/10);
	text-align: center;
	letter-spacing: -0.02em;
	@include respond($bp-classes-stack) {
		text-align: left;
		font-size: 1.8rem;
		line-height: calc(22/18);
	}
}

.classes-nav-tab:hover,
.classes-nav-tab:focus {
	.classes-nav-tab-text {
		color: $color-gold;
	}
}

.classes-nav-tab.is-active {
	.classes-nav-tab-image:before {
		border: 2px solid $color-gold;
	}
	@include respond($bp-classes-stack) {
		&:after {
			content: "";
			position: absolute;
			left: 100%;
			top: 50%;
			transform: translate(-29px, -50%);
			width: 92px;
			height: 42px;
			background-image: url(../svg/xx.svg);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: center center;
		}
	}
}

.classes-nav-cta {
	
	border-bottom: 1px solid $color-border;
	border-top: 1px solid $color-border;
	padding: 30px 34px 30px 20px;
	@include respond($bp-classes-stack) {
		padding: 58px 60px 58px 20px;	
	}
	@include respond($bp-classes-md) {
		padding: 40px 40px 40px 40px;	
	}
}

.classes-panels {	
	height: 550px;
	z-index: $z_index-level1;
	position: relative;
	@include respond($bp-classes-stack) {
		flex: 0 0 60%;
		width: 60%;
		order: 2;	
		min-height: 550px;
		height: auto;
	}
	@include respond($bp-classes-lg) {
		flex: 0 0 72%;	
		width: 72%;
	}
}

.class-panel {
	background-color: $color-body;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	height: 100%;
	align-items: flex-end;
	justify-content: flex-start;
	display: none;
	position: relative;
	padding: 32px;
	text-align: center;
	&.lazyload {
		background: none !important;
	}
	@include respond($bp-classes-sm) {
		padding: 22px 40px;	
		text-align: left;
	}
	@include respond($bp-classes-md) {
		padding: 64px;
	}
	@include respond($bp-classes-lg) {
		padding: 100px 108px;
	}
	
	&.is-active {
		display: flex;
	}
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 70%;
		background: linear-gradient(360deg, rgba(45, 45, 46, 0.7) 0%, rgba(35, 59, 95, 0) 104.66%);
		background-blend-mode: multiply;
		z-index: $z_index-level1;
	}
}

.class-panel-text {
	position: relative;
	z-index: $z_index-level1 + 1;
	max-width: 430px;
	
	p {
		left: -40px;
		position: relative;
		opacity: 0;
		color: $color-border;
		font-size: 1.5rem;
		line-height: calc(25/15);
		margin: 0 0 23px 0;
		@include respond($bp-padding) {
			font-size: 1.6rem;
			margin: 0 0 34px 0;
		}
	}
}


.class-panel-title {
	font-family: $font;
	color: #fff;
	font-style: italic;
	font-weight: 800;
	font-size: 2.4rem;
	line-height: calc(28/24);	
	letter-spacing: -0.02em;
	text-transform: uppercase;
	margin: 0 0 12px 0;
	left: -40px;
	position: relative;
	opacity: 0;
	@include respond($bp-padding) {
		font-size: 3.4rem;
		line-height: 1;
		margin: 0 0 21px 0;
	}
}


.class-panel .button-row {
	position: relative;
	left: -40px;
	opacity: 0;
	@include respond($bp-classes-sm, "down") {
		justify-content: center;
		.btn {
			margin: 0 8px 16px 8px;
		}
	}
}

.is-scrolled {
	.classes-text-outline {
		transform: translate(0);
		opacity: 1;
	}
}