.mc-field-group {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	border-bottom: 1px solid #C4C4C4;
	padding: 11px 0 26px 0;
	position: relative;
	font-family: $font;
	input {
		color: $color-body;
		font-family: $font;
		background: transparent;
		font-weight: 400 !important;
		flex: 0 0 calc(100% - 40px);
		font-size: 1.7rem;
		line-height: calc(25/17);		
		&::placeholder {
			color: $color-body;
			opacity: 1;
		}
	}
}


#mc-embedded-subscribe {
	flex: 0 0 25px;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	background: transparent;
	svg {
		opacity: 0.8;
		width: 25px;
		height: 25px;		
	}	
	&:hover,
	&:focus {
		svg .arrow {
			fill: $color-gold !important;
			stroke: $color-gold !important;
		}
	}
}

input.mce_inline_error {
	border: none;
	color: tomato;
	&::placeholder {
		color: tomato;	
	}
}

div.mce_inline_error {
	position: absolute;
	top: 100%;
	left: 0;
	padding: 8px 0 0 0 !important;
	font-weight: 400 !important; 
	color: tomato !important;
	font-size: 1.2rem;
	margin: 0;
	background: none !important;
	
}

#mc_embed_signup div#mce-responses {float:left; top:-1.4em; padding:0; overflow:hidden; width:100%; margin: 0; clear: both;}
#mc_embed_signup div.response {margin:1em 0; padding:0; font-size: 1.2rem; float:left; top:-1.5em; z-index:1; width:100%;}
#mc_embed_signup #mce-error-response {display:none;}
#mc_embed_signup #mce-success-response {color:#529214; display:none;}

#mc_embed_signup #num-subscribers {font-size:1.1em;}
#mc_embed_signup #num-subscribers span {padding:.5em; border:1px solid #ccc; margin-right:.5em; font-weight:bold;}


