$bp-program-lg: 1124px;
$bp-program-md: 768px;
$bp-program-sm: 580px;

.program {
	padding: 40px 0 80px 0;
	position: relative;
	@include respond($bp-program-md) {
		padding: 136px 0 236px 0;	
	}
	&:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		height: 100%;
		background: linear-gradient(180deg, rgba(230, 231, 232, 0.4) 0%, rgba(230, 231, 232, 0) 100%);
		transform: rotate(180deg);
		max-height: 1100px;
	}
}

.program .container {
	position: relative;
	z-index: $z_index-level1 + 1;
}


.program-cards {
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	gap: 18px;
	@include respond($bp-program-sm) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 15px;
	}
	@include respond($bp-program-md) {
		grid-template-columns: repeat(3, minmax(0, 1fr));	
	}
	@include respond($bp-program-lg) {
		gap: 30px;
	}
}

.program-cards-outline {
	position: absolute;
	left: -62px;
	top: -37px;
	max-width: 95vw;
	height: auto;
	z-index: $z_index-level1;
	transform: translateX(-80px);
	opacity: 0;
	transition: opacity 0.4s ease, transform 0.8s ease;
	@include respond($bp-outline, "down") {
		height: 45px;
		max-width: none;
		width: auto;
		left: -32px;
	}
	@include respond($bp-program-sm) {
		top: -67px;	
	}
}

.program-cards-box {
	position: relative;
	z-index: $z_index-level1 + 1;
	padding-top: (554 / 420) * 100%;
	border-radius: 3px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	transform: translateY(50px);
	opacity: 0;
	transition: opacity 0.4s ease, transform 1s ease;
	overflow: hidden;
	&.lazyload {
		background: none !important;
	}
	&:before {
		content: "";
		position: absolute;
		width: 380px;
		height: 579px;
		transition: transform 1s ease;
		background-image: url(../svg/program-icon.svg);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		z-index: $z_index-level1 + 1;
		@include respond($bp-program-sm, "down") {
			width: 262px;
			height: 400px;
		}
	}
	&:nth-child(2):before {
		bottom: -70%;
		left: -212px;
		@include respond($bp-program-md, "down") {
			display: none;
		}
	}
	&:nth-child(3):before {
		display: none;
	}
	&:nth-child(4):before {
		right: -190px;
		top: -346px;
		@include respond($bp-program-md, "down") {
			display: none;
		}
	}	
	
	&:nth-child(5):before {
		bottom: 30%;
		left: -204px;
		@include respond($bp-program-md, "down") {
			display: none;
		}
	}
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 66%;
		background: linear-gradient(360deg, rgba(45, 45, 46, 0.8) 0%, rgba(35, 59, 95, 0) 104.66%);
		background-blend-mode: multiply;
		z-index: $z_index-level1;
	}
	&:hover {
		@include respond($bp-program-md){
			&:nth-child(2):before {
				transform: translate(20px, -20px);
			}
			&:nth-child(5):before {
				transform: translate(20px, 20px);
			}
			&:nth-child(4):before {
				transform: translate(20px, 20px);	
			}
		}
	}
}

.program-cards-box-text {
	position: absolute;
	z-index: $z_index-level1 + 2;
	left: 24px;
	right: 24px;
	bottom: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@include respond($bp-program-lg) {
		left: 58px;
		right: 43px;
		bottom: 43px;	
	}
}

.program-cards-box-text-left {
	color: #fff;
	text-decoration: none;
	font-weight: 800;
	font-style: italic;
	font-size: 2.2rem;
	line-height: calc(24/22);
	letter-spacing: -0.02em;
	text-transform: uppercase;
	max-width: 230px;
	@include respond(420px) {
		max-width: none;
	}
	@include respond($bp-padding) {
		font-size: 2.8rem;
		line-height: calc(30/28);		
	}
	span {
		color: $color-gold;
	}
	.program-cards-box-text-tag {
		padding: 15px 0 0 0;
		font-size: 1.1rem;
		line-height: calc(12/11);
		span {
			text-transform: uppercase;
			
			background: #fff;
			font-weight: 700;
			font-family: $font;
			letter-spacing: -0.02em;
			color: $color-body;
			font-style: normal;
			padding: 2px 3px;	
			display: inline-block;
		}		
	}
}

.program-cards-box-text-right {
	svg {
		@include respond($bp-padding) {
			width: 38px;
			height: 40px;
		}
	}
}

.program-cards-box:hover,
.program-cards-box:focus {
	
	.program-cards-box-text-right svg .arrow {
		fill: $color-gold;
		stroke: $color-gold;
	}
}

.program-cards-box:nth-child(3),
.program-cards-box:nth-child(6){
	@include respond($bp-program-md) {
		transform: translateY(-50px);
		margin: 100px 0 -100px 0;
	}
}


// -----------------------------------------------------------------------------
//! Animations
// -----------------------------------------------------------------------------

.is-scrolled {
	.program-cards-outline,
	.program-cards-box {
		transform: translate(0);
		opacity: 1;
	}
	.program-cards-box:hover,
	.program-cards-box:focus {
		transform: translateY(-8px);
		@include respond($bp-program-md){
			transform: translateY(-20px);
		}
	}
}