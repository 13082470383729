// -----------------------------------------------------------------------------
//! SIZING
// -----------------------------------------------------------------------------

@font-feature-values "Sharp Grotesk" {
	@styleset {
		alt-i: 2;
	}
}

html {
	font-size: 62.5%;
}

body {
	font-size: 1.6rem;
	font-family: $font;
	color: $color-body;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-variant-ligatures: common-ligatures;
	//font-variant-alternates: styleset(alt-i);
	font-feature-settings: "ss02";
}

// -----------------------------------------------------------------------------
//! HEADINGS
// -----------------------------------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-accent;
	line-height: 1.1;
	font-weight: 700;
	margin: 1em 0;
	color: $color-body;

	&:first-child {
		margin-top: 0;
	}
}

// -----------------------------------------------------------------------------
//! COPY
// -----------------------------------------------------------------------------

p {
	line-height: calc(25/16);
	margin: 1em 0;
	font-size: 1.6rem;

	&:first-child {
		margin-top: 0;
	}

	small {
		font-size: 1.3rem;
		font-weight: 400;
	}
}

a {
	color: $color-blue;
	text-decoration: underline;
	transition: all 0.4s ease;

	&:hover {
		color: $color-gold;
	}
}

svg * {
	transition: all 0.4s ease;
}

ul,
ol {
	margin: 1em 0;
	padding: 0 0 0 24px;

	li {
		padding: 0 0 0 8px;
		margin: 0 0 8px 0;
		line-height: 1.5;
		position: relative;
	}
}


.section-title {
	font-weight: bold;
	font-size: 4.2rem;
	line-height: calc(39/42);
	text-transform: uppercase;
	margin: 0 0 24px 0;
	position: relative;

	@include respond($bp-padding) {
		font-size: 6rem;
		line-height: calc(55/60);
	}
}

// -----------------------------------------------------------------------------
//! Line Highlight
// -----------------------------------------------------------------------------

.lineHighlight {
	position: relative;

	.lineHighlight-title {
		font-family: $font;
		font-weight: 800;
		font-size: 1.6rem;
		font-style: italic;
		color: $color-gold;
		line-height: calc(16/16);
		letter-spacing: -0.02em;
		text-transform: uppercase;
		margin: 0 0 51px 0;
		position: relative;

		span {
			color: $color-body;
		}

		@include respond($bp-padding) {
			margin: 0 0 53px 0;
			font-size: 1.8rem;
			line-height: calc(20/18);
		}

		// &:before {
		// 	content: "";
		// 	position: absolute;
		// 	top: calc(100% + 21px);
		// 	height: 3px;
		// 	right: 100%;
		// 	width: 50vw;
		// 	background: $color-gold;
		// }
		&:after {
			content: "";
			position: absolute;
			top: calc(100% + 21px);
			height: 3px;
			right: calc(100% - 80px);
			width: 200px;
			background: $color-gold;
		}
	}

	&.no-line .lineHighlight-title:after {
		display: none;
	}
}


// -----------------------------------------------------------------------------
//! Outline blocks
// -----------------------------------------------------------------------------


.outline-text * {
	@include respond(500px, "down") {
		fill: #c4c4c4 !important;
	}
}