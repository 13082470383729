$bp-footer-lg: 940px;
$bp-footer-stack: 580px;

.footer {
	padding: 80px 0 75px 0;
	position: relative;
	background: #F5F5F6;
	&:before {
		content: "";
		position: absolute;
		width: 15px;
		height: 240px;
		right: 0;
		top: 58px;
		background-image: url(../images/diagonal-dark.png);
		background-repeat: repeat-y;
		background-position: left top;
		background-size: 100% auto;
		@include respond($bp-footer-stack) {
			top: 85px;
			width: 22px;
			height: 220px;
		}		
	}
	
	@include respond($bp-footer-stack) {
		padding: 85px 0 75px 0;
	}
}

.footer .container {
	max-width: 1200px;
	display: grid;
	grid-template-columns: 1fr;
	gap: 30px 20px;
	text-align: center;
	@include respond($bp-footer-stack) {
		grid-template-columns: 1fr 1fr;
		text-align: left;
	}
	@include respond($bp-footer-lg) {
		grid-template-columns: minmax(auto, 312px) minmax(auto, 312px) minmax(auto, 450px);	
		gap: 30px 20px;
	}
}


.footer-box {
	
}

.footer-box.is-email,
.footer-box.is-social {
	padding-left: 80px;
	@include respond($bp-footer-lg, "down") {
		grid-column: auto / span 2;
		padding: 0;
	}
	@include respond($bp-footer-stack, "down") {
		grid-column: auto;	
	}
}

.footer-box.is-nav {
	@include respond($bp-footer-stack) {
		grid-column: auto / span 2;
	}
}

.footer-box-title {
	letter-spacing: -0.02em;
	text-transform: uppercase;
	font-weight: 800;
	font-style: italic;
	font-size: 1.6rem;
	line-height: calc(18/16);
	text-transform: uppercase;
	font-family: $font;
	margin: 0 0 12px 0;
	@include respond($bp-footer-stack) {
		font-size: 1.8rem;
		line-height: calc(20/18);
		margin: 0 0 19px 0;
	}
}

.footer-box-text {
	margin: 0 0 6px 0;
	font-size: 1.4rem;
	line-height: calc(23/14);
	
	@include respond($bp-footer-stack) {
		font-size: 1.6rem;
		line-height: calc(25/16);
		margin: 0 0 9px 0;
	}
}

.footer-box-phone {
	text-decoration: none;
	font-weight: 700;
	font-size: 1.4rem;
	line-height: calc(23/14);
	color: $color-body;
	@include respond($bp-footer-stack) {
		font-size: 1.6rem;
		line-height: calc(25/16);
	}
	&:hover,
	&:focus {
		color: $color-gold;
	}
}

.footer-nav {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
	@include respond($bp-footer-stack) {
		justify-content: flex-start;	
	}
	li {
		margin: 0 8px 8px 8px;
		padding: 0;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		font-size: 1.3rem;
		line-height: calc(23/13);
		@include respond($bp-footer-stack) {
			font-size: 1.5rem;
			line-height: calc(28/15);
			margin: 0 0 8px 0;
		}
		.footer-nav-divider {
			padding: 0 8px;
			color: rgba(65, 64, 66, 0.75);
			@include respond($bp-footer-stack, "down") {
				display: none;
			}
		}
	}
	
	a {
		color: rgba(65, 64, 66, 0.75);
		text-decoration: none;
		&:hover,
		&:focus {
			color: $color-gold;
		}
	}
}

.footer-box.is-social {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	@include respond($bp-footer-stack) {
		justify-content: flex-start;
	}
	@include respond($bp-footer-lg) {
		order: 5;
	}
	a {
		display: block;
		text-decoration: none;
		margin: 0 30px 0 0;
		svg * {
			fill: $color-body;
		}
		&:last-of-type {
			margin: 0;
		}
		&:hover svg *,
		&:focus svg * {
			fill: $color-gold;
		}
	}
}

.rockSteady-link {
	margin: 16px 0 0 0;
	a {
		display: inline-block;
	}
}
#rockSteady {
	width: auto;
	height: 40px;
	display: block;
	
}