.scheduleBlock {
    .container {
        justify-content: center;
        align-items: flex-start;
        gap: 130px;
    }

    .altblock-image {
        position: relative;
        @include respond($bp-altblock-stack) {
            flex: 1 1 560px;
            max-width: 560px;
        }

        &:after {
            content: "";
            position: absolute;
            right: -85px;
            top: 90px;
            // transform: translate(-130px, 50%);
            width: 122px;
            height: 56px;
            background-image: url(../svg/xx.svg);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center center;
            @include respond($bp-quotes-stack, "down") {
                width: 78px;
                height: 36px;
                // transform: translate(-34px, 50%);
                top: -15px;
                right: 50px;
            }
        }
    }

    .scheduled-item {
        border-bottom: 1px solid $color-border;
        margin-bottom: 16px;

        p {
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 25px;
        }
    }

    .item-date {
        font-family: $font;
        font-style: normal;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 27px;
        margin-bottom: 0;
    }

    .item-title {
        font-family: $font;
        font-style: italic;
        font-weight: 900;
        font-size: 2.2rem;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 16px;

        @include respond($md) {
            font-size: 2rem;
        }
    }

    .btn {
        margin-top: 32px;
    }
}
