$bp-altblock-stack: 940px;

.altblock {
	position: relative;
	margin: 80px 0;
	@include respond($bp-padding) {
		margin: 150px 0;
	}
}

.altblock .container {
	@include respond($bp-altblock-stack) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 1330px;
	}
}

.altblock-content {
	padding: 40px 0 0 0;
	opacity: 0;
	transition: transform 0.8s ease, opacity 0.8s ease;
	@include respond($bp-mobile) {
		padding: 75px 0 0 0;	
	}
	@include respond($bp-altblock-stack) {
		max-width: 450px;
		flex: 1 1 450px;	
		padding: 0;
	}
}

.altblock.image-right .altblock-image {
	order: 2;
	transform: translateX(80px);
	img {
		border-radius: 3px;
	}
}

.altblock.image-right .altblock-content {
	transform: translateX(-80px);
	@include respond($bp-altblock-stack) {
		margin-right: 64px;
	}
}

.altblock.image-left .altblock-content {
	transform: translateX(80px);
	@include respond($bp-altblock-stack) {
		margin-left: 64px;
	}
}

.altblock-image {
	position: relative;
	z-index: $z_index-level1;
	opacity: 0;
	transition: transform 0.8s ease, opacity 0.8s ease;
	transform: translateX(-80px);
	@include respond($bp-altblock-stack) {
		flex: 1 1 688px;
		max-width: 688px;
	}
	img {
		display: block;
	}
}

.is-scrolled {
	.altblock-image,
	.altblock-content {
		transform: translateX(0) !important;
		opacity: 1 !important;
	}
}

.altblock-preTitle {
	font-style: italic;
	font-weight: 800;
	font-size: 1.6rem;
	line-height: calc(20/16);
	margin: 0 0 12px 0;
	text-transform: uppercase;
	@include respond($bp-padding) {
		margin: 0 0 22px 0;	
		font-size: 1.8rem;
		line-height: calc(20/18);
	}
}

.altblock-ctas {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin: 40px 0 0 0;
	a {
		margin: 0 17px 17px 0;
	}
}


.altblock-1,
.altblock-5 {
	&:before {
		content: "";
		position: absolute;
		left:0;
		
		width: 15px;
		height: 116px;
		background-image: url(../images/diagonal-dark.png);
		background-repeat: repeat-y;
		background-position: left top;
		background-size: 100% auto;
		bottom: 17px;
		@include respond($bp-altblock-stack) {
			bottom: 0;	
		}
		@include respond($bp-padding) {
			width: 22px;
			height: 260px;
		}
	}
}

.altblock-3,
.altblock-7 {
	&:before {
		content: "";
		position: absolute;
		right:0;
		width: 15px;
		height: 116px;
		background-image: url(../images/diagonal-dark.png);
		background-repeat: repeat-y;
		background-position: left top;
		background-size: 100% auto;
		bottom: 17px;
		@include respond($bp-altblock-stack) {
			bottom: 0;	
		}
		@include respond($bp-padding) {
			width: 22px;
			height: 260px;
		}
	}
}

.altblock-bgwrap {
	position: relative;
	z-index: $z_index-level1 + 1;
	background: linear-gradient(180deg, rgba(230, 231, 232, 0) 0%, rgba(230, 231, 232, 0.4) 100%);
	padding-bottom: 80px;
	.altblock:last-of-type {
		margin-bottom: 0;
	}
	@include respond($bp-padding) {
		padding-bottom: 141px;
	}
	
	&:after {
		content: "";
		position: absolute;
		right: 90px;
		bottom: 0;
		transform: translate(0, 50%);
		width: 122px;
		height: 56px;
		background-image: url(../svg/xx.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		@include respond($bp-padding, "down") {
			width: 78px;
			height: 36px;
			transform: translate(0, 50%);
			right: 32px;
		}
	}
	
}

.page-covid-19 {
	.altblock {
		margin-top: 16px;
	}
	.altblock .container {
		position: relative;
	}
	.thanks-text-outline {
		z-index: -1;
		position: absolute;
		top: calc(100% - 20px);
		right: -70px;
		width: 907px;
		max-width: 90vw;
		height: auto;
		@include respond($bp-padding) {
			top: calc(100% + 50px);
		}
		@include respond($bp-outline, "down") {
			height: 45px;
			max-width: none;
			width: auto;
			right: auto;
			left: 12px;
			top:calc(100% + 40px);
		}
	}
}


.page-private-events {
	.altblock-content {
		position: relative;
	}
	.altblock-image {
		position: relative;
	}
	.altblock-image:before {
		content: "";
		position: absolute;
		width: 92px;
		height: 42px;
		background-image: url(../svg/xx.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center center;		
		z-index: $z_index-level1 + 1;
		left: -29px;
		bottom: 56px;
		@include respond($bp-mobile, "down") {
			width: 78px;
			height: 36px;
			left: -24px;
			
		}
	}
}

