.iframeContainer {
	padding: 80px 0;
	position: relative;
	@include respond($bp-padding) {
		padding: 100px 0 146px 0;
	}
	
	.container {
		position: relative;
		max-width: 1376px;
		z-index: $z_index-level1 + 1;
	}
	&:before {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		height: 100%;
		background: linear-gradient(180deg, rgba(230, 231, 232, 0.4) 0%, rgba(230, 231, 232, 0) 100%);
		transform: rotate(180deg);
	}
}

.location-bar {
	padding: 0 0 20px 0;
	margin: 0 0 30px 0;
	border-bottom: 1px solid $color-border;
	display: none;
	&.is-visible {
		display: block;
	}
}

.location-bar-wrap {
	position: relative;
}

.location-bar-button {
	display: inline-block;
	padding: 8px 24px 8px 0;
	font-weight: bold;
	color: $color-body;
	cursor: pointer;
	background: transparent;
	font-size: 1.3rem;
	line-height: calc(14/13);
	text-transform: uppercase;
	position: relative;
}

.location-bar-button .caret {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	width: 12px;
	height: 12px;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	border: none;
	margin: 0;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjAwMDE2IDBIOC4yNTAxNlYzLjkyODUzTDEyIDMuOTI4NTNWOC4xNzg1M0g4LjI1MDE2VjEySDQuMDAwMTZWOC4xNzg1M0gwVjMuOTI4NTNMNC4wMDAxNiAzLjkyODUzVjBaTTUuMDAwMTYgNC45Mjg1M0wxIDQuOTI4NTNWNy4xNzg1M0g1LjAwMDE2VjExSDcuMjUwMTZWNy4xNzg1M0gxMVY0LjkyODUzSDcuMjUwMTZWMUg1LjAwMDE2VjQuOTI4NTNaIiBmaWxsPSIjNDE0MDQyIi8+Cjwvc3ZnPgo=");
}

.location-bar-button.is-active .caret {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI1MDE2IDFINS4wMDAxNlY0LjkyODUzTDEgNC45Mjg1M1Y3LjE3ODUzSDUuMDAwMTZWMTFINy4yNTAxNlY3LjE3ODUzSDExVjQuOTI4NTNMNy4yNTAxNiA0LjkyODUzVjFaIiBmaWxsPSIjQjc5NzU2Ii8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNC4wMDAxNiAwSDguMjUwMTZWMy45Mjg1M0wxMiAzLjkyODUzVjguMTc4NTNIOC4yNTAxNlYxMkg0LjAwMDE2VjguMTc4NTNIMFYzLjkyODUzTDQuMDAwMTYgMy45Mjg1M1YwWk01LjAwMDE2IDQuOTI4NTNMMSA0LjkyODUzVjcuMTc4NTNINS4wMDAxNlYxMUg3LjI1MDE2VjcuMTc4NTNIMTFWNC45Mjg1M0g3LjI1MDE2VjFINS4wMDAxNlY0LjkyODUzWiIgZmlsbD0iI0I3OTc1NiIvPgo8L3N2Zz4K");
}

.location-bar-dropdown {
	display: none;
	position: absolute;
	left: 0;
	top: 100%;
	border: 1px solid $color-border;
	border-radius: 5px;
	padding: 12px 16px;
	background: #fff;
	z-index: $z_index-level5;
	a {
		display: block;
		padding: 4px 0;
		color: $color-body;
		font-size: 1.6rem;
		line-height: calc(20/16);
		white-space: nowrap;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
			color: $color-gold;
		}
	}
}

.location-bar-wrap.has-dropdown .location-bar-dropdown {
	display: block;
}


#zingfit-embed iframe {
	min-height: 500px;
}