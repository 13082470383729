.page-error {
	background: $color-blue;
}

.page-error .pageTitle {
	position: relative;
	z-index: $z_index-level1 + 1;
	background: transparent;
	&:before {
		display: none;
	}
	.hero-title {
		text-shadow: none;
	}
}

.error-gif {
	position: absolute;
	z-index: $z_index-level1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 550px;
	height: 336px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	max-width: 90vw;
	max-height: 90vh;
	background-image: url(../images/error.gif);
}