$bp-insta-lg: 768px;
$bp-insta-sm: 560px;

.insta {
	padding: 80px 0;
	@include respond($bp-padding) {
		padding: 140px 0 90px 0;
	}
}

.insta-grid {
	display: grid;
	gap: 14px 20px;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	@include respond($bp-insta-sm) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		gap: 18px;	
	}
	@include respond($bp-insta-lg) {
		grid-template-columns: repeat(5, minmax(0, 1fr));	
		a:nth-child(6) {
			display: none;
		}
	}

	a {
		text-decoration: none;
		position: relative;
		overflow: hidden;
		border-radius: 3px;
		padding-top: 100%;
		display: block;
		background-size: cover;
		background-position: center top;
		background-repeat: no-repeat;
		&.lazyload {
			background-image: none;
		}
		&:before,
		&:after {
			content:"";
			position: absolute;
		}
		&:before {
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			transition: opacity 0.4s ease;
			opacity: 0;
			background: linear-gradient(0deg, rgba(35, 59, 95, 0.85), rgba(35, 59, 95, 0.85));
		}
		&:after {
			top: 50%;
			left: 50%;
			width: 28px;
			height: 28px;
			background-image: url(../svg/insta.svg);
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 100% 100%;
			transform: scale(0.1) translate(-50%,-50%);
			opacity: 0;
			transform-origin: left top;
			transition: opacity 0.4s ease, transform 0.8s ease;
		}
	}
	a:hover,
	a:focus {
		&:before {
			opacity: 1;
		}
		&:after {
			opacity: 1;
			transform: scale(1) translate(-50%,-50%);
		}
	}
	a img {
		display: block;
	}
}