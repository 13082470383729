
.modal-overlay {
	position: fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	background: $color-blue;
	z-index: $z_index-level6;
	display: none;
}

.modal-wrap {
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index: $z_index-level6 + 1;
	max-height: 100%;
	max-width: 100%;
	display: none;
}

.modal {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	padding: 16px;
	@include respond($bp-padding) {
		padding: 32px;
	}
}

.modal-inner {
	background:#fff;
	padding: 20px;
	max-width: 880px;
	margin: 0 auto;
	position: relative;
	padding: 56px 20px 32px 20px;
	border-radius: 3px;
	@include respond(400px) {
		padding: 56px 32px 40px 32px;	
	}
	@include respond($bp-padding) {
		padding: 56px 60px 64px 60px;	
	}
	&.is-video {
		max-width: 1440px;
		background: transparent;
		box-shadow: none;
		padding: 24px 0;
	}
}

.modal .section-title {
	text-align: center;
}

.modal-video-embed {
	position: relative; 
	padding-bottom: 56.25%; 
	height: 0; 
	overflow: hidden; 
	max-width: 100%; 
	iframe {
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		 height: 100%; 
		 box-shadow: 0 20px 40px 10px rgba(35,31,32,0.15);

	}
}

.modal-share {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: absolute;
	left:0;
	bottom:0;
	a {
		margin-right:20px;
		text-decoration: none;
		svg * {
			fill:#fff;
			transition: fill 0.2s ease;
		}
		&:hover svg * {
			fill: $color-gold;
		}
	}
}