
.gear {
	padding: 80px 0;
	@include respond($bp-padding) {
		padding: 147px 0;
	}
}

.gear-grid {
	display: grid;
	grid-template-columns: 1fr;
	gap: 40px;
	@include respond(480px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));	
	}
	@include respond($bp-padding) {
		grid-template-columns: repeat(3, minmax(0, 1fr));		
	}
	@include respond(1200px) {
		gap: 75px 90px;	
	}
}



.gear-grid-item {	
	position: relative;
	z-index: $z_index-level1 + 1;
}

.gear-grid-item-icon {
	height: 70px;
	display: flex;
	align-items: flex-end;
	margin-bottom: 20px;
	svg,
	img {
		//transform: translateY(-10px);
		display: block;
		max-height: 70px;
		width: auto;
	}
}

.gear-grid-item-title {
	font-family: $font;
	font-style: italic;
	font-weight: 900;
	font-size: 1.8rem;
	line-height: calc(24/22);
	letter-spacing: -0.02em;
	text-transform: uppercase;
	margin: 10px 0 0 0;
	@include respond($bp-padding) {
		font-size: 2.2rem;
	}
	
	small,
	span,
	strong,
	em {
		padding-left: 5px;
		color: rgba(65, 64, 66, 0.6);
		font-style: normal;
		font-weight: 700;
		font-size: 1.3rem;
		display: inline-block;
	}
}

.gear-grid-item-text {
	margin: 17px 0 0 0;
}

.gear-grid-title {
	position: relative;
	.section-title {
		position: relative;
		z-index: $z_index-level1 + 1;
	}
}

.gear-text-outline {
	position: absolute;
	left: -12px;
	top: 65px;
	max-width: 90vw;
	height: auto;
	z-index: $z_index-level1;
	
	@include respond($bp-mobile) {
		left: -81px;
		top: 82px;		
		top: 12px;
	}
	@include respond($bp-outline, "down") {
		height: 90px;
		width: auto;
		max-width: none;
	}
}

.noneed-title {
	margin: 100px 0 30px 0;
	
	background-repeat: repeat-x;
	background-position: center center;
	background-size: auto;
	@include respond($bp-mobile) {
		background-image: url(../svg/noneed.svg);	
	}
	span {
		
		display: inline-block;
		padding: 0 22px 0 0;
		font-size: 2.4rem;
		line-height: calc(26/24);
		font-weight: 800;
		font-style: italic;
		font-family: $font;
		letter-spacing: -0.02em;
		text-transform: uppercase;
		@include respond($bp-mobile) {
			background: #fff;
		}
		@include respond($bp-padding) {
			line-height: calc(31/28);
			font-size: 2.8rem;
		}
	}
}

.noneed-grid {
	display: grid;
	gap:40px;
	@include respond($bp-mobile) {
		grid-template-columns: repeat(2, minmax(0, 1fr));	
	}
	@include respond($bp-padding) {
		grid-template-columns: repeat(3, minmax(0, 1fr));		
	}
	@include respond(1200px) {
		gap: 90px;	
	}
}

.noneed-block-title {
	font-size: 1.8rem;
	line-height: calc(25/18);
	font-family: $font;
	font-weight: 700;
	padding: 0 0 0 30px;
	position: relative;
	&:before {
		content:"";
		height: 18px;
		width: 18px;
		position: absolute;
		background-image: url(../svg/x.svg);
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center center;
		left: 0;
		top: 3px;
	}
}

.gear-bg {
	background: linear-gradient(180deg,hsla(210,4%,91%,0),hsla(210,4%,91%,.4))
}