$bp-prevnext: 630px;

.page-blog-post {
	.header.non-sticky {
		.header-logo svg * {
			fill: $color-body;
		}

		.hamburger .bar {
			background: $color-body;
		}
	}
}

.blog-post {
	margin-top: 75px;
	padding: 80px 0 114px;

	.breadcrumbs,
	.post-title,
	.post-categories,
	.post-content {
		max-width: 790px;
		margin-left: auto;
		margin-right: auto;
	}

	.post-image {
		max-width: 870px;
		margin-left: auto;
		margin-right: auto;
	}

	.breadcrumbs {
		text-align: center;
		font-size: 1.6rem;
		font-weight: 500;
		line-height: calc(25/16);
		margin-bottom: 24px;

		a {
			text-decoration: underline;
		}
	}

	.post-title h1 {
		font-family: $font;
		line-height: 1;
		font-size: 2.2rem;
		font-weight: 800;
		font-style: italic;
		text-align: center;
		text-transform: uppercase;
		margin: 0 0 24px 0;
		position: relative;
		// opacity: 0;
		// transform: translateX(-80px);
		// transition: opacity 0.4s ease, transform 0.8s ease;

		@include respond($bp-padding) {
			font-size: 3.4rem;
		}
	}

	.post-categories {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		gap: 16px;
		flex-wrap: wrap;
		margin-bottom: 24px;
	}

	.post-image {
		margin-bottom: 24px;

		img {
			display: block;
		}
	}

	.post-content {
		padding: 28px 0 0;

		@include respond(540px) {
			padding: 40px 40px 0;
		}

		h1,
		h2,
		h3,
		h4 {
			font-family: $font;
			font-style: italic;
			text-transform: uppercase;
		}
	}

	.post-nextprev {
		margin-top: 64px;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		gap: 80px;
		max-width: 840px;
		margin-left: auto;
		margin-right: auto;

		@include respond($bp-prevnext, "down") {
			flex-wrap: wrap;
		}

		> a {
			flex: 1;
			max-width: 380px;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: stretch;
			text-decoration: none;
			gap: 16px;

			@include respond($bp-prevnext, "down") {
				flex: 1 0 100%;
			}

			&.post-prev {
				align-items: flex-start;

				.arrow-link svg {
					transform: scaleX(-1);
				}
			}

			span {
				display: block;
				font-family: $font;
				font-size: 1.8rem;
				font-weight: 800;
				line-height: 1;
				letter-spacing: -0.02em;
				font-style: italic;
				text-transform: uppercase;
				margin: 0;

				@include respond($bp-prevnext) {
					font-size: 2.2rem;
				}

			}

			&.post-next span {
				text-align: right;
			}

			@include respond($bp-prevnext, "down") {
				&.post-next {
					order: 1;
				}

				&.post-prev {
					order: 2;
				}
			}
		}

		.arrow-link {
			gap: 16px;
		}
	}
}