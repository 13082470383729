$slider-bp: 880px;

.sliderBlock {
    // padding-top: 67px;
    // padding-bottom: 67px;

    // @include respond($md) {
    //     padding-top: 150px;
    //     padding-bottom: 150px;
    // }

    .sliderBlock-text {
        margin-bottom: 40px;
        display: block;

        @include respond($md) {
            display: flex;
            justify-content: space-between;
            margin-bottom: 80px;
        }

        h2 {
            max-width: 640px;
        }

        p {
            margin: 0;
        }

        h2,
        p {
            flex: 1;
        }
    }

    .sliderBlock-wrapper {
        border: 1px solid $color-border;
        border-radius: 3px;
        display: flex;
        flex-direction: column-reverse;

        @include respond($slider-bp) {
            flex-direction: row;
            align-items: stretch;
            justify-content: space-between;
            gap: 72px;
            height: 500px;
        }
    }

    .left-text {
        flex: 0 1 auto;
        padding: 40px;

        @include respond($slider-bp) {
            flex: 0 1 500px;
            padding: 68px 0px 107px 60px;
        }

        h3 {
            font-family: $font;
            font-size: 2.8rem;
            font-style: italic;
            font-weight: 800;
            text-transform: uppercase;
            margin-bottom: 24px;
        }

        p {
            margin-bottom: 28px;

            @include respond($slider-bp) {
                margin: 0 0 48px;
            }
        }
    }

    .location-graphics {
        overflow: hidden;
        position: relative;
        border-radius: 3px 3px 0 0;
        flex: 0 1 auto;

        @include respond($bp-locations-stack) {
            flex: 1 1 688px;
            max-width: 688px;
            order: 2;
            border-radius: 0 3px 3px 0;
        }

        &:after {
            content: "";
            position: absolute;
            z-index: $z_index-level1;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(
                0deg,
                rgba(65, 64, 66, 0.7) 0%,
                rgba(65, 64, 66, 0) 100%
            );
            background-blend-mode: multiply;
            height: 80px;
            @include respond($bp-locations-stack) {
                height: 144px;
            }
        }
    }

    .image-slide {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        @include respond($bp-locations-stack, "down") {
            padding-top: (530 / 650) * 100%;
            height: 0;
        }
    }

    .page-personal-training {
        .swiper-slide.image-slide {
            height: 100%;
            padding: 0;
        }
    }
}
