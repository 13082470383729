.press {
	padding: 0 0 80px 0;
	@include respond($bp-padding) {
		padding: 0 0 150px 0;
	}
}

.press-grid {
	display: grid;
	gap: 32px;
	grid-template-columns: 1fr;
	@include respond(640px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	@include respond($bp-padding) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		gap: 54px;
	}
}

.press-box {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.press-box-image {
	border-radius: 5px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	flex: 0 0 60px;
	width: 60px;
	height: 60px;
	@include respond(340px) {
		flex: 0 0 100px;
		width: 100px;
		height: 100px;
	}
	&.lazyload {
		background-image: none !important;
	}
}

.press-box-content {
	padding: 0 0 0 28px;
	width: calc(100% - 60px);
	flex: 0 0 calc(100% - 60px);
	@include respond(340px) {
		width: calc(100% - 100px);
		flex: 0 0 calc(100% - 100px);
	}
	
}

.press-box-source {
	margin: 0 0 4px 0;
	font-size: 1.5rem;
	line-height: calc(24/15);
}

.press-box-title {
	display: block;
	font-size: 1.6rem;
	font-weight: 700;
	letter-spacing: -0.02em;
	line-height: calc(22/16);
	color: $color-body;
	text-decoration: none;
	&:hover {
		color: $color-gold;
	}
}