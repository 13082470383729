$bp-staff-sm: 560px;

.staff {
	padding: 80px 0;
	position: relative;
	@include respond($bp-padding) {
		padding: 160px 0 150px 0;
	}
	
}

.staff .container {
	max-width: 1384px;
	position: relative;
	z-index: $z_index-level1;
}

.staff-title {
	font-family: $font;
	line-height: 1;
	font-size: 2.4rem;
	font-weight: 800;
	font-style: italic;
	text-transform: uppercase;
	margin: 0 0 32px 0;
	@include respond($bp-padding) {
		font-size: 3.4rem;	
		margin: 0 0 50px 0;
	}
}

.staff-grid {
	display: grid;
	gap: 16px;
	grid-template-columns: 1fr 1fr;
	@include respond($bp-staff-sm) {
		gap: 20px 24px;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	}
}

.staff-box {
	padding-top: (408 / 312) * 100%;
	background: $color-border;
	position: relative;
	overflow: hidden;
	border-radius: 3px;
	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 30%;
		background: linear-gradient(0deg, rgba(65, 64, 66, 0.7) 0%, rgba(65, 64, 66, 0) 100%);
		background-blend-mode: multiply;
		transition: opacity 0.4s ease;
		z-index: $z_index-level1 + 1;
	}
	
}

.staff-box-image {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	transition: all 0.8s ease;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url(../images/staff-default.jpg);
	&.lazy-load {
		background-image: none !important;
	}
}

.staff-box:hover .staff-box-image {
	left: -16px;
	top: -16px;
	bottom: -16px;
	right: -16px;
}

.staff-box-text {
	position: absolute;
	z-index: $z_index-level1 + 3;
	left: 16px;
	bottom: 18px;
	right: 16px;
	color: #fff;
	font-size: 1.6rem;
	line-height: calc(20/16);
	font-weight: 800;
	font-style: italic;
	transition: opacity 0.4s ease;
	text-transform: uppercase;
	@include respond($bp-staff-sm) {
		left: 26px;
		bottom: 28px;
		right: 26px;	
	}
	@include respond($bp-padding) {
		font-size: 2.2rem;	
		line-height: calc(24/18);
	}
}

.staff-box-hover {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	transition: opacity 0.4s ease;
	opacity: 0;
	background: linear-gradient(0deg, rgba(35, 59, 95, 0.85), rgba(35, 59, 95, 0.85));
	display: flex;
	align-items: center;
	justify-content: center;
}

.staff-box:hover,
.staff-box:focus {
	&:before {
		opacity: 0;
	}
	.staff-box-text {
		opacity: 0;
	}
	.staff-box-hover {
		opacity: 1;
	}
}