$bp-tm-stack: 840px;

.tm-body {
	padding: 120px 0 80px 0;
	@include respond($bp-padding) {
		padding: 196px 0 150px 0;
	}
}

.tm-body-back {
	position: relative;	
	margin: 0 0 49px 0;
	@include respond($bp-mobile) {
		margin: 0 0 69px 0;
	}
	a {
		color: $color-body;
		position: relative;
		z-index: $z_index-level1 + 1;
		display: inline-flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 1.8rem;
		line-height: calc(24/22);
		text-transform: uppercase;
		font-style: italic;
		font-weight: 800;
		text-decoration: none;
		@include respond($bp-padding) {
			font-size: 2.2rem;
		}
		svg {
			margin: 0 16px 0 0;
			flex-shrink: 0;
			transform: rotate(180deg);
		}
		&:hover,
		&:focus {
			color: $color-gold;
			svg {
				opacity: 1;
			}
			svg .arrow {
				fill: $color-gold !important;
				stroke: $color-gold !important;
			}
		}
	}
}

.coach-outline {
	position: absolute;
	top: -32px;
	right: -80px;
	z-index: $z_index-level1;
	max-width: 90vw;
	height: auto;
	@include respond($bp-outline, "down") {
		height: 45px;
		max-width: 100%;
		
		top: 580px;
		right: -60px;
	}
}


.tm-body-split {
	@include respond($bp-tm-stack) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
}

.tm-body-info {
	padding: 32px 0 0 0;
	position: relative;
	@include respond($bp-tm-stack) {
		padding: 30px 0 0 32px;
		flex: 1 1 610px;
		max-width: 610px;
	}
	@include respond($bp-padding) {
		padding: 30px 0 0 65px;
	}
}

.tm-body-type {
	color: $color-gold;
	font-size: 1.8rem;
	line-height: calc(20/18);
	padding-bottom: 21px;
	position: relative;
	font-style: italic;
	text-transform: uppercase;
	font-weight: 800;
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		height: 3px;
		background: $color-gold;
		right: 100%;
		transform: translateX(86px);
		width: 300px;
	}
}

.tm-body-title {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	font-size: 4rem;
	text-transform: uppercase;
	line-height: 1;
	font-weight: bold;
	margin: 32px 0 29px 0;
	@include respond($bp-padding) {
		font-size: 6rem;
	}
}

.tm-body-title-social {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 8px 0 0 0;
	@include respond($bp-padding) {
		padding: 15px 0 0 0;	
	}
	a, svg {
		display: block;
	}
	a {
		margin: 0 0 0 16px;
	}
	a:focus svg *,
	a:hover svg * {
		fill: $color-gold;
	}
}

.tm-body-content {
	.btn {
		margin: 48px 0 0 0;
	}
}


.tm-body-slideshow {
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;
	overflow: hidden;
	position: relative;
	z-index: $z_index-level1 + 1;
	margin: 0 0 62px 0;
	@include respond($bp-mobile) {
		margin: 0;
	}
	@include respond(640px) {
		grid-template-columns: 110px calc(100% - 130px);
	}
	@include respond($bp-tm-stack) {
		flex: 1 1 632px;
		max-width: 632px;
	}
}

#tm-thumbs {
	position: relative;
	margin: 0 26px;
	grid-row: 2;
	@include respond(640px) {
		height: 360px;
		margin: 26px 0;
		grid-row: 1;
	}
	@include respond(768px) {
		height: 480px;
	}
	@include respond(1024px) {
		height: 590px;
	}
	.swiper-slide {
		height: auto !important;
		.tm-thumbs-image{
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			background-clip: content-box;
			border-radius: 5px;
			display: block;
			padding:3px;
			border: 2px solid #fff;
			border-radius: 8px;
			width: 100px;
			height: 100px;
			position: relative;
			@include respond(640px, "down") {
				width: 54px;
				height: 54px;
			}
		}
	}
	.swiper-slide-thumb-active .tm-thumbs-image  {
		border-color: $color-gold;
	}
	
	.tm-thumbs-image.has-video:before {
		content: "";
		position: absolute;
		background:linear-gradient(0deg, rgba(65, 64, 66, 0.4), rgba(65, 64, 66, 0.4));
		background-blend-mode: multiply;
		top: 3px;
		left:  3px;
		bottom:  3px;
		right:  3px;
		border-radius: 5px;
		z-index: $z_index-level1;
	}
	.tm-thumbs-image.has-video:after {
		content: "";
		position: absolute;
		z-index: $z_index-level1 + 1;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 17px;
		height: 14px;
		background-size: auto;
		background-position: center center;
		background-repeat: no-repeat;
		background-image: url(../svg/play.svg);
	}
}

.thumb-button-prev {
	position: absolute;

	bottom: 22px;
	transform: rotate(-90deg);
	left: 0;
	height: 15px;
	svg {
		width: 20px;
	}
	@include respond(640px) {
		left: 55px;	
		top: 0;
		bottom: auto;
		transform: translateX(-50%);
		height: auto;
		svg {
			width: 24px;
		}
	}
	&.swiper-button-disabled {
		opacity: 0;
	}
}

.thumb-button-next {
	position: absolute;
	bottom: 22px;
	transform: rotate(-90deg);
	right: 0;
	height: 15px;
	svg {
		width: 20px;
	}
	@include respond(640px) {
		left: 55px;	
		right: auto;
		transform: translateX(-50%);
		bottom: 0;
		height: auto;
		svg {
			width: 24px;
		}
	}
	&.swiper-button-disabled {
		opacity: 0;
	}
}
#tm-swiper {
	width: 100%;
	grid-row: 1;
	
	.swiper-slide {
		max-width: 100%;
		position: relative;
		img {
			border-radius: 5px;
		}
	}
	.tm-swiper-slide-inner{
		width: 100%;
		display: block;
		position: relative;
		padding-top: (652 / 505) * 100%;
		background-color: $color-body;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		border-radius: 3px;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;			
			width: 100%;
		}
	}
}
