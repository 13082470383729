$bp-locations-xl: 1100px;
$bp-locations-stack: 880px;
$bp-locations-sm: 540px;

.locations {
	padding: 80px 0;
	@include respond($bp-padding) {
		padding: 147px 0 166px 0;
	}
	
	.container {
		max-width: 1368px;
		position: relative;
	}
}

.location-outline {
	position: absolute;
	left: -16px;
	top: -37px;
	max-width: 95vw;
	height: auto;
	z-index: $z_index-level1;
	transform: translateX(-80px);
	opacity: 0;
	transition: opacity 0.4s ease, transform 0.8s ease;
	@include respond($bp-outline, "down") {
		height: 45px;
		max-width: none;
		width: auto;
		left: 4px;
	}
	@include respond($bp-locations-stack) {
		top: -73px;	
		left: -44px;
	}
}

.location {
	background:#fff;
	border-radius: 3px;
	//border: 1px solid $color-border;
	margin: 0 0 18px 0;
	position: relative;
	z-index: $z_index-level1 + 1;
	transition: box-shadow 0.4s ease;
	box-shadow: 0px 0px 0px 1px $color-border;
	&:last-of-type {
		margin: 0;
	}
	@include respond($bp-locations-stack) {
		margin: 0 0 29px 0;
		display: flex;
		align-items: stretch;
		justify-content: space-between;
	}
	&:hover {
		box-shadow: 0px 0px 0px 3px $color-gold;
	}
}

.location-details {
	padding: 40px 24px;
	@include respond($bp-locations-sm) {
		padding: 32px;
	}
	@include respond($bp-locations-stack) {
		flex: 1 1 632px;
		max-width: 632px;
	}
	@include respond($bp-locations-xl) {
		padding: 64px 64px 48px 56px;	
	}
}

.location-graphics {
	overflow: hidden;
	position: relative;
	border-radius: 3px 3px 0 0;
	@include respond($bp-locations-stack) {
		flex: 1 1 688px;
		max-width: 688px;	
		order: 2;
		border-radius: 0 3px 3px 0;
	}
	&:after {
		content: "";
		position: absolute;
		z-index: $z_index-level1;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(0deg, rgba(65, 64, 66, 0.7) 0%, rgba(65, 64, 66, 0) 100%);
		background-blend-mode: multiply;
		height: 80px;
		@include respond($bp-locations-stack) {
			height: 144px;	
		}
	}
}




.swiper-container-horizontal > .swiper-pagination-bullets.location-pagination.swiper-pagination {
	z-index: $z_index-level1 + 1;
	bottom: 16px;
	@include respond($bp-padding) {
		bottom: 36px;
	}
	.swiper-pagination-bullet {
		background: #fff;
		opacity: 0.6;
		transition: all 0.4s ease;
		width: 6px;
		height: 6px;
		border-radius: 3px;
		&.swiper-pagination-bullet-active {
			opacity: 1;
			width: 22px;
		}
	}
}

.location-button-prev,
.location-button-next {
	opacity: 0;
	transition: opacity 0.4s ease;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: $z_index-level1 + 3;
	cursor: pointer;
	svg {
		width: 38px;
		height: 40px;
	}
	&:hover,
	&:focus {
		svg .arrow {
			fill: #fff;
		}
	}
}

.location-graphics:hover {
	.location-button-prev,
	.location-button-next {
		opacity: 1;
		
	}
}

.location-button-prev {
	left: 26px;
	svg {
		transform: rotate(180deg);
	}
}

.location-button-next {
	right: 26px;
}

.location-slide {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	@include respond($bp-locations-stack, "down") {
		padding-top: (530 / 650) * 100%;
		height: 0;
	}
}

.page-personal-training {
	.swiper-slide.location-slide {
		height: 100%;
		padding: 0;
	}
}

.location-title {
	font-family: $font;
	line-height: 1;
	font-size: 2.2rem;
	font-weight: 800;
	font-style: italic;
	text-transform: uppercase;
	margin: 0 0 16px 0;
	position: relative;
	opacity: 0;
	transform: translateX(-80px);
	transition: opacity 0.4s ease 0.4s, transform 0.8s ease 0.4s;
	@include respond($bp-padding) {
		font-size: 2.8rem;	
		margin: 0 0 24px 0;
	}
}

.location-text {
	margin: 0 0 24px 0;
}

.location-stats {
	display: grid;
	grid-template-columns: 1fr;	
	gap: 24px;
	margin: 0 0 24px 0;
	@include respond($bp-locations-sm) {
		grid-template-columns: 200px 1fr;	
		margin: 0 0 40px 0;
	}
	@include respond($bp-locations-stack) {
		grid-template-columns: 1fr;	
	}
	@include respond($bp-locations-xl) {
		grid-template-columns: 200px 1fr;	
	}
}

.location-stats-left {
	font-size: 1.5rem;
	line-height: calc(24/15);
	a {
		margin: 6px 0 0 0;
		font-weight: 700;
		text-decoration: none;		
		display: block;
		color: $color-body;
		&:hover {
			color: $color-gold;
		}
	}
}

.location-stats-right {
	margin: 0;
	padding: 0 0 0 16px;
	li {
		font-size: 1.5rem;
		line-height: calc(20/15);
		margin: 0 0 5px 0;
		padding: 0;
	}
}

.locations .is-scrolled {
	.location-outline,
	.location-title {
		opacity: 1;
		transform: translate(0);
	}
}
