$bp-hero-stack: 768px;
$bp-hero-sm: 560px;

.hero {
	padding: 252px 0 36px 0;
	background-color: $color-dark;
	background-image: url(../webp/hero-v2.webp);
	background-size: auto calc(100% - 250px);
	background-repeat: no-repeat;
	background-position: center top;
	position: relative;
	@include respond($bp-hero-sm) {
		background-position: center center;	
		background-size: cover;
	}
	@include respond($bp-hero-stack) {
		padding: 356px 0 72px 0;
	}
	&:before {
		content:"";
		position: absolute;
		bottom: 250px;
		left: 0;
		right: 0;
		height: 130px;
		background: linear-gradient(354.74deg, $color-dark 14.15%, rgba(45, 45, 46, 0.5) 56.05%, rgba(65, 64, 66, 0) 81.09%, rgba(35, 59, 95, 0) 91.11%);
		@include respond($bp-hero-sm) {
			display: none;
		}
	}
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 15px;
		height: 116px;
		background-image: url(../images/diagonal-light.png);
		background-repeat: repeat-y;
		background-position: right top;
		background-size: 100% auto;
		@include respond($bp-hero-sm) {
			width: 22px;
			height: 212px;
		}
	}
	
	.container {
		position: relative;
		z-index: 2;
	}
}

.no-webp .hero {
	background-image: url(../images/hero-v2.jpg)
}

.hero-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 140px;
	z-index: $z_index-level1;
	background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
	background-blend-mode: multiply;
	mix-blend-mode: multiply;
	opacity: 0.5;
	@include respond($bp-mobile) {
		height: 213px;
	}
}

.hero-text {
	max-width: 560px;
	margin: 0 0 72px 0;
	@include respond($bp-hero-stack) {
		margin: 0 0 126px 54px;
	}
	
	&.no-margin {
		margin-bottom: 0;
	}
}

.hero-title {
	text-align: center;
	font-size: 5.5rem;
	line-height: 1;
	font-style: italic;
	color:#fff;
	text-transform: uppercase;
	margin: 0 0 20px 0;
	text-shadow: 0px 0px 20px rgba(#222222,0.9);
	
	@include respond($bp-hero-sm) {
		font-size: 7.5rem;
		span {
			margin-top: 0;
		}
	}
	@include respond($bp-hero-stack) {
		text-align: left;	
		font-size: 10rem;
		line-height: calc(85/100);
		margin: 0 0 42px 0;
		text-shadow: 0px 0px 40px rgba(#222222,0.9);
	}
	
	&.is-small {
		font-size: 4.2rem;
		@include respond($bp-hero-sm) {
			font-size: 5.8rem;
		}

	}
	span {
		position: relative;
		opacity: 0;
		transform: translateX(-40px);
		font-family: $font;
		color: $color-gold;
		display: block;
		font-weight: 800;
		letter-spacing: -0.05em;
		margin: -8px 0 0 0;
		&.show {
			opacity: 1;
			transform: none;
		}
	}
	
}

.no-margin .hero-title {
	margin-bottom: 0;
}

.hero-text .button-row {
	position: relative;
	opacity: 0;
	transform: translateY(40px);
	@include respond($bp-hero-stack, "down") {
		justify-content: center;
		.btn {
			margin: 0 8px 16px 8px;
		}
	}
}

.hero-video {
	@include respond($bp-hero-stack) {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		padding: 0 50px 0 0;
	}
}

.hero-video-wrap {
	opacity: 0;
	max-width: 135px;
	margin: 0 auto 32px auto;
	position: relative;
	@include respond($bp-hero-stack) {
		margin: 0;	
		flex: 1 1 210px;
		max-width: 210px;
	}
	&:after {
		content: "";
		position: absolute;
		width: 185px;
		top: calc(100% + 24px);
		left: -25px;
		height: 3px;
		background: $color-gold;
		@include respond($bp-hero-stack) {
			left: 100%;
			top: 40px;
			width: 131px;
		}
	}
}

.hero-video-trigger {
	background-image: url(../webp/hype-video-v2.webp);
	background-color: $color-gold;
	background-size: cover;
	background-position: center center;
	padding: 0;
	padding-top: (139 / 209) * 100%;
	display: block;
	width: 100%;
	position: relative;
	border-radius: 1px;
	transition: transform 1s ease;
	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -15px);
	}
	&:hover {
		transform: translateY(-10px);
	}
	&:hover svg * {
		fill: $color-gold;
	}
	
}

.no-webp .hero-video-trigger {
	background-image: url(../images/hype-video.jpg);
}

.hero-video-text {
	position: relative;
	opacity: 0;
	transform: translateX(-40px);
	font-size: 1.6rem;
	line-height: calc(25/16);
	color: #fff;
	text-align: center;
	max-width: 270px;
	margin: 0 auto;	
	@include respond($bp-hero-stack) {
		margin: 0;
		text-align: left;
		padding: 0 0 0 36px;
		max-width: 314px;
		flex: 1 1 314px;
		font-size: 1.6rem;
		line-height: calc(25/16);
	}
}