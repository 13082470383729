.eventForm {
	padding: 0 0 80px 0;
	position: relative;
	@include respond($bp-padding) {
		padding: 0 0 138px 0;
	}
	
	&:before {
		content: "";
		position: absolute;
		width: 15px;
		height: 116px;
		left: 0;
		bottom: 0;
		background-image: url(../images/diagonal-dark.png);
		background-repeat: repeat-y;
		background-position: left top;
		background-size: 100% auto;
		@include respond($bp-padding) {
			width: 22px;
			height: 292px;
		}
	}
}

.eventForm .container {
	max-width: 1068px;
}

.eventForm-wrap {
	padding: 40px 25px 25px;
	background: linear-gradient(180deg, rgba(230, 231, 232, 0.4) 0%, rgba(230, 231, 232, 0) 100%);
	border-radius: 3px;
	position: relative;
	@include respond($bp-padding) {
		padding: 70px 90px 80px 90px;
	}
	&:before {
		content: "";
		position: absolute;
		width: 233px;
		height: 111px;
		left: 0;
		bottom: 0;		
		background: linear-gradient(54.59deg, rgba(196, 196, 196, 0.2) 0%, rgba(196, 196, 196, 0) 39.61%);
	}
}

.eventForm-content {
	max-width: 520px;
	margin: 0 0 24px 0;
	opacity: 0;
	transform: translateX(-80px);
	transition: opacity 0.4s ease, transform 0.8s ease;
	@include respond($bp-padding) {
		margin: 0 0 40px 0;	
	}
	p {
		@include respond($bp-mobile, "down") {
			font-size: 1.4rem;
			line-height: calc(23/14);
		}
	}
}

.is-scrolled .eventForm-content {
	opacity: 1;
	transform: translateX(0);
}

.eventForm-title {
	font-family: $font;
	line-height: 1;
	font-size: 2.2rem;
	font-weight: 800;
	font-style: italic;
	text-transform: uppercase;
	margin: 0 0 24px 0;
	position: relative;
	@include respond($bp-padding) {
		font-size: 3.4rem;	
		margin: 0 0 32px 0;
	}
}

.book-text-outline {
	position: absolute;
	z-index: $z_index-level0;
	bottom: 210px;
	left: -95px;
	max-width: 90vw;
	@include respond($bp-padding) {
		bottom: calc(100% - 26px);
		left: -124px;	
	}
	@include respond($bp-outline, "down") {
		height: 45px;
		width: auto;
		bottom: 230px;
		z-index: -1;
		left: -35px;
	}
}

.page-private-events .eventForm .btn {
	min-width: calc(50% - 18px);
	@include respond($bp-padding) {
		margin-top: 14px;
	}
}