$bp-safe-stack: 1024px;
$bp-safe-grid: 540px;

.safe {
	padding: 80px 0 0 0;
	@include respond($bp-padding) {
		padding: 146px 0 100px 0;
	}
}

.safe .container {
	@include respond($bp-safe-stack) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
}

.safe-text {
	flex: 1 1 470px;
	max-width: 470px;
	padding: 0 0 60px 0;
	@include respond($bp-safe-stack) {
		padding: 0 80px 0 0;
	}
}

.safe-grid {
	
	display: grid;
	gap: 40px;
	grid-template-columns: 1fr;
	@include respond($bp-safe-grid) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	@include respond($bp-safe-stack) {
		flex: 1 1 calc(100% - 470px);
		max-width: calc(100% - 470px);
	}
	@include respond(1200px) {
		gap: 90px;	
	}
}

.safe-grid-item {
	@include respond($bp-safe-grid) {
		max-width: 365px;
	}
}

.safe-grid-item-icon {
	height: 65px;
	svg,
	img {
		display: block;
		max-height: 65px;
		width: auto;
	}
}

.safe-grid-item-title {
	font-family: $font;
	font-style: italic;
	font-weight: 900;
	font-size: 1.8rem;
	line-height: calc(24/22);
	letter-spacing: -0.02em;
	text-transform: uppercase;
	margin: 10px 0 0 0;
	@include respond($bp-padding) {
		font-size: 2.2rem;
	}
}

.safe-grid-item-text {
	margin: 17px 0 0 0;
}