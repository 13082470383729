$bp-booking-xl: 1200px;
$bp-booking-stack: 768px;
$bp-booking-sm: 500px;

.booking {
	position: relative;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: $color-border;
	background-image: url('../webp/booking-bg.webp');
	padding: 80px 0;
	@include respond($bp-padding) {
		padding: 140px 0 150px 0;
	}
	&:after {		
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(-130px, -50%);
		width: 122px;
		height: 56px;
		background-image: url(../svg/xx.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		@include respond($bp-booking-stack, "down") {
			width: 78px;
			height: 36px;
			transform: translate(-34px, -50%);
		}		
	}
}

.no-webp .booking {
	background-image: url('../images/booking-bg.jpg');
}

.booking .container {
	display: grid;
	
	gap: 28px;
	position: relative;
	z-index: $z_index-level1 + 1;
	grid-template-columns: 1fr;
	@include respond($bp-booking-stack) {
		grid-template-columns: repeat(2, minmax(0, 1fr));	
	}	
	@include respond($bp-padding) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

.booking-text {
	padding-right: 30px;
	.section-title {
		color: #fff;
		margin: 0 0 30px 0;
	}
	@include respond($bp-booking-stack) {
		grid-column: 1 / span 2;
	}
	@include respond($bp-padding) {
		grid-column: 1;	
	}
}

.booking-text-content {
	* {
		color: #fff;
	}
}

.booking-block {
	background: rgba(65, 64, 66, 0.8);
	border-radius: 3px;
	position: relative;
	padding: 24px 24px 40px 24px;
	@include respond($bp-booking-sm) {
		padding: 40px 52px 62px 58px;
	}
	@include respond($bp-booking-stack) {
		padding: 40px 32px 116px 32px;
	}
	@include respond($bp-booking-xl) {
		padding: 40px 52px 116px 58px;
	}
}

.booking-block-num {
	color: $color-gold;
	font-weight: 800;
	font-size: 4.0rem;
	line-height: calc(80/50);
	font-style: italic;
	@include respond($bp-padding) {
		font-size: 5.0rem;
	}
}

.booking-block-title {
	font-size: 1.8rem;
	line-height: calc(24/18);
	color: #fff;
	margin: 10px 0 13px 0;
	text-transform: uppercase;
	font-weight: 800;
	font-style: italic;
	font-family: $font;
	letter-spacing: -0.02em;
	@include respond($bp-padding) {
		line-height: calc(30/22);
		font-size: 2.2rem;
	}
}

.booking-block-text {
	margin: 0 0 26px 0;
}

.booking-block-text * {
	color: #fff;
}

.booking-block-apps {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	
	margin: 0 0 36px 0;
	a {
		flex: 0 0 auto;
		margin: 0 0 10px 0;
		img, 
		svg {
			height: 34px;
			width: auto;
		}
		@include respond($bp-mobile) {
			flex: 0 0 auto;	
			
			img, 
			svg {
				height: 44px;
				width: auto;
			}
		}
		@include respond(1000px) {
			img, 
			svg {
				height: 34px;
				width: auto;
			}
		}
		@include respond($bp-booking-xl) {
			img, 
			svg {
				height: 44px;
				width: auto;
			}
		}
	}
	a:first-of-type {
		margin: 0 10px 10px 0;
	}
}

.booking-block .btn {
	width: 100%;
	@include respond($bp-booking-stack) {
		position: absolute;
		bottom: 62px;
		left: 32px;
		right: 32px;
		width: auto;
	}
	@include respond(1200px) {
		left: 58px;
		right: 52px;	
	}
}