@font-face{
	font-family:"Sharp Grotesk";
	src:url("../fonts/SharpGroteskBold15.woff2") format("woff2"),
		url("../fonts/SharpGroteskBold15.woff") format("woff");
	font-weight: 700;
	font-display: swap;
}
@font-face{
	font-family:"Sharp Grotesk";
	src:url("../fonts/SharpGroteskBoldItalic15.woff2") format("woff2"),
		url("../fonts/SharpGroteskBoldItalic15.woff") format("woff");
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}