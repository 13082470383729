$bp-hero-stack: 768px;
$bp-hero-sm: 560px;


.pageTitle {
	padding: 252px 0 36px 0;

	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	position: relative;

	@include respond($bp-hero-sm) {
		background-position: center center;
		background-size: cover;
	}

	@include respond($bp-hero-stack) {
		padding: 356px 0 72px 0;
	}

	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 130px;
		background: linear-gradient(354.74deg, $color-dark 14.15%, rgba(45, 45, 46, 0.5) 56.05%, rgba(65, 64, 66, 0) 81.09%, rgba(35, 59, 95, 0) 91.11%);

		@include respond($bp-hero-sm) {
			background: linear-gradient(180deg, rgba(45, 45, 46, 0.7) 0%, rgba(45, 45, 46, 0.45) 48.44%, rgba(45, 45, 46, 0) 100%);
			background-blend-mode: multiply;
			transform: rotate(180deg);
			bottom: 0;
			height: 260px;
		}
	}

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 15px;
		height: 116px;
		background-image: url(../images/diagonal-light.png);
		background-repeat: repeat-y;
		background-position: right top;
		background-size: 100% auto;

		@include respond($bp-hero-sm) {
			width: 22px;
			height: 212px;
		}
	}

	.container {
		position: relative;
	}


	&.pageTitle-thin {
		padding: 202px 0 86px 0;
		background-size: cover;

		@include respond($bp-hero-stack) {
			padding: 244px 0 80px 0;
		}

		&:before {
			bottom: 0;
		}
	}
}

.pageTitle .hero-text {
	margin: 28px auto 100px auto;

	@include respond($bp-hero-stack) {
		margin: 42px 0 143px 54px;
	}

	&.no-margin {
		margin-bottom: 0;
	}
}

.pageTitle.less-margin .hero-text {
	margin: 0 auto 72px auto;

	@include respond($bp-hero-stack) {
		margin: 0 0 100px 54px;
	}

	&.no-margin {
		margin-bottom: 0;
	}
}

.pageTitle .hero-title {
	font-size: 5.5rem;

	@include respond($bp-hero-sm) {
		font-size: 7.5rem;
	}

	@include respond($bp-hero-stack) {
		font-size: 9.0rem;
	}

	&.is-small {
		font-size: 4.2rem;

		@include respond($bp-hero-sm) {
			font-size: 4.2rem;
		}

		@include respond($bp-hero-stack) {
			font-size: 5.4rem;
		}
	}
}

.pageTitle .hero-title span {
	color: #fff;
}

.pageTitle-subtext {
	position: relative;
	color: #fff;
	font-size: 1.6rem;
	line-height: calc(25/16);
	margin: 0 auto;
	max-width: 320px;
	text-align: center;

	@include respond($bp-hero-stack) {
		margin: 0;
		flex: 1 1 320px;
		text-align: left;
		margin-right: 110px;
	}

	&:after {
		content: "";
		position: absolute;
		width: 131px;
		bottom: calc(100% + 16px);
		left: 50%;
		transform: translateX(-50%);
		height: 3px;
		background: $color-gold;

		@include respond($bp-hero-stack) {
			left: -37px;
			transform: translateX(0);
		}
	}
}

.pageTitle-zing-default {
	background-image: url(../images/zing-default-v2.jpg);
}

.pageTitle-zing-buy {
	background-image: url(../images/zing-buy.jpg);
}

.page-contact .pageTitle {
	background-image: url(../images/contact-hero.jpg);
}

.page-covid-19 .pageTitle {
	background-image: url(../images/covid-hero.jpg);
}