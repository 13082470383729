.pricing-header {
	padding: 92px 0 40px 0;
	p {
		max-width: 545px;
	}
}

.pricing-header-title {
	font-family: $font;
	font-style: italic;
	font-weight: 800;
	font-size: 2.4rem;
	line-height: calc(28/24);	
	letter-spacing: -0.02em;
	text-transform: uppercase;
	margin: 0 0 12px 0;
	position: relative;
	@include respond($bp-padding) {
		font-size: 3.4rem;
		line-height: 1;
		margin: 0 0 21px 0;
	}
}

.pricing-header:first-of-type  {
	padding-top: 0;
}