$bp-nubie-stack: 768px;

.nubieCTA {
	padding: 0 0 80px 0;
	@include respond($bp-padding) {
		padding: 0 0 104px 0;	
	}
}



.nubieCTA .container {
	max-width: 936px;
}

.nubieCTA-inner {
	position: relative;
	border-radius: 5px;
	overflow: hidden;
	background-size: cover;
	background-position: left bottom;
	background-repeat: no-repeat;
	background-image: url(../svg/nubie-bg.svg);
	padding: 206px 25px 25px 25px;
	@include respond($bp-nubie-stack) {
		padding: 25px 60% 25px 25px;
	}
	@include respond(900px) {
		padding: 71px 88px 46px 88px;	
	}
	
}

.nubieCTA-image {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	max-width: 491px;
	height: 372px;
	background-size: cover;
	background-position: bottom left;
	background-repeat: no-repeat;
	background-image: url(../webp/nubie-image-2x.webp);
	@include respond($bp-nubie-stack, "down") {
		bottom: auto;
		height: 226px;
		top: -50px;
		width: 298px;
	}
	&.lazyload {
		background: none;
	}
}

.no-webp .nubieCTA-image {
	background-image: url(../images/nubie-image-2x.png);
	&.lazyload {
		background: none;
	}
}




.nubieCTA-text {	
	@include respond($bp-nubie-stack) {
		max-width: 308px;
	}
}

.nubieCTA-title {
	font-family: $font;
	font-style: italic;
	text-transform: uppercase;
	letter-spacing: -0.02em;
	margin: 0 0 21px 0;
	font-size: 2.2rem;
	line-height: calc(20/22);	
	@include respond($bp-padding) {
		font-size: 2.8rem;
		line-height: calc(30/28);
	}
}

.nubieCTA-text p {
	margin: 0 0 34px 0;
	@include respond($bp-nubie-stack, "down") {
		font-size: 1.4rem;
		line-height: calc(23/14);
		margin: 0 0 24px 0;
	}
}

.nubieCTA-text-cta {
	border-top: 1px solid $color-border;
	padding: 24px 0 0 0;
	@include respond($bp-nubie-stack) {
		padding: 32px 0 0 0;	
	}
}

.page-team,
.page-our-team {
	.nubieCTA {
		@include respond($bp-padding) {
			padding-bottom: 150px;
		}
	}
}
.page-team .nubieCTA .nubieCTA-image,
.page-our-team .nubieCTA .nubieCTA-image{
	background-image: url(../webp/jointeam-2x.webp);
	background-position: left center;
	height: 100%;
	background-size: auto 100%;
	&.lazyload {
		background: none;
	}
}

.page-personal-training .nubieCTA  {
	position: relative;
	z-index: $z_index-level1 + 1;
	padding-top: 80px;
	position: relative;
	@include respond($bp-padding) {
		padding-top: 138px;
	}
	&:after {
		content: "";
		position: absolute;
		left: 90px;
		top: 0;
		transform: translate(0, -50%);
		width: 122px;
		height: 56px;
		background-image: url(../svg/xx.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		@include respond($bp-padding, "down") {
			width: 78px;
			height: 36px;
			transform: translate(0, -50%);
		}
		@include respond($bp-mobile, "down") {
			display: none;
		}
	}
}
.page-personal-training .nubieCTA .nubieCTA-image{
	background-image: url(../webp/female-boxer.webp);
	background-position: right top;
	@include respond($bp-mobile, "down") {
		top: 0;
		height:  176px;
	}
	&.lazyload {
		background: none;
	}
	
	
}

