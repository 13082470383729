.genHead {
	padding: 120px 0 16px 0;
	@include respond($bp-padding) {
		padding: 244px 0 16px 0;
	}
}

.genContent {
	padding: 0 0 80px 0;
	@include respond($bp-padding) {
		padding: 0 0 143px 0;	
	}
}

.genContent,
.genHead {
	.container {
		max-width: 938px;
	}
}

.genHead h1 {
	font-family: $font;
	line-height: 1;
	font-size: 2.4rem;
	font-weight: 800;
	font-style: italic;
	text-transform: uppercase;
	margin:  0;
	letter-spacing: -0.03em;
	@include respond($bp-padding) {
		font-size: 3.4rem;	
		line-height: calc(36/34);
	}
}

.genContent {
	
	h1, h2, h3, h4, h5, h6 {
		margin: 2.5rem 0 1.5rem 0;
		font-family: $font;
		font-weight: 800;
		color: $color-dark;
		font-style: italic;
		letter-spacing: -0.02em;
		text-transform: uppercase;
	}
	
	h1 {
		font-family: $font;
		line-height: 1;
		font-size: 2.4rem;
		font-weight: 800;
		font-style: italic;
		text-transform: uppercase;
		letter-spacing: -0.03em;
		@include respond($bp-padding) {
			font-size: 3.4rem;	
			line-height: calc(36/34);
		}
	}
	
	h2 {
		font-family: $font;
		line-height: 1;
		font-size: 2.0rem;
		font-weight: 800;
		font-style: italic;
		text-transform: uppercase;
		@include respond($bp-padding) {
			font-size: 2.4rem;	
			line-height: calc(26/24);
		}
	}
	
	h3 {
		font-family: $font;
		line-height: 1;
		font-size: 1.6rem;
		font-weight: 800;
		font-style: italic;
		text-transform: uppercase;
		@include respond($bp-padding) {
			font-size: 1.8rem;	
			line-height: calc(20/18);
		}
	}
	
	h4 {
		font-family: $font;
		line-height: 1;
		font-size: 1.4rem;
		font-weight: 800;
		font-style: italic;
		text-transform: uppercase;
		@include respond($bp-padding) {
			font-size: 1.6rem;	
			line-height: calc(18/16);
		}
	}
	
	h5 {
		font-family: $font;
		line-height: 1;
		font-size: 1.3rem;
		font-weight: 800;
		font-style: italic;
		text-transform: uppercase;
		@include respond($bp-padding) {
			font-size: 1.4rem;	
			line-height: calc(16/14);
		}
	}
	
	h6 {
		font-family: $font;
		line-height: 1;
		font-size: 1.2rem;
		font-weight: 800;
		font-style: italic;
		text-transform: uppercase;
		@include respond($bp-padding) {
			font-size: 1.3rem;	
			line-height: calc(16/13);
		}
	}
	
	
}