$bp-owner-stack: 700px;
$bp-owner-sm: 600px;

.owners {
	position: relative;
	z-index: $z_index-level1 + 1;
	padding: 80px 0;
	background: linear-gradient(180deg, #414042 0%, #202020 100%);
	@include respond($bp-padding) {
		padding: 130px 0 136px 0;
	}
	
	&:after {
		content: "";
		position: absolute;
		left: 32px;
		bottom: 0;
		transform: translate(0px, 50%);
		
		width: 78px;
		height: 36px;
		background-image: url(../svg/xx.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		@include respond(768px) {
			width: 122px;
			height: 56px;	
		}
	}
}

.owners .container {
	max-width: 1368px;
}

.owners .section-title {
	max-width: 660px;
	color: #fff;
	margin: 0 0 24px 0;
	opacity: 0;
	transition: opacity 0.4s ease;
}

.owner-text {
	opacity: 0;
	transition: opacity 0.4s ease 0.2s;
	@include respond(600px) {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 30px;		
	}
	p {
		margin: 0 0 1.6rem 0;		
		color: $color-border;
		@include respond($bp-padding) {
			padding-right: 40px;	
		}
	}
}

.owner-split {
	margin: 60px 0 0 0;
	display: grid;
	gap: 30px;
	grid-template-columns: 1fr;
	position: relative;
		@include respond($bp-owner-stack) {
		grid-template-columns: repeat(2, minmax(0, 1fr));	
	}
	@include respond($bp-padding) {
		margin: 116px 0 0 0;
	}
}

.owner-outline {
	position: absolute;
	right: -48px;
	top: -42px;
	z-index: $z_index-level1;
	transform: translateX(80px);
	opacity: 0;
	transition: opacity 0.4s ease, transform 0.8s ease;
	max-width: 95vw;
	height: auto;
	@include respond($bp-outline, "down") {
		height: 45px;
		max-width: none;
		width: auto;
		top: -36px;
	}
	@include respond($bp-outline, "down") {
		* {
			fill: #fff !important;
		}
	}
	@include respond($bp-padding) {
		top: -72px;	
	}

}

.owner {
	padding-top: 100%;
	position: relative;
	z-index: $z_index-level1 + 1;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: #3F3E40;
	overflow: hidden;
	opacity: 0;
	border-radius: 3px;
	transition: opacity 0.4s ease, transform 0.8s ease;
	text-align: left;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: $z_index-level1;
		background-image: linear-gradient(0deg, rgba(65, 64, 66, 0.7) 0%, rgba(65, 64, 66, 0) 100%);
		background-blend-mode: multiply;
		background-size: 100% 144px;
		background-position: bottom center;
		background-repeat: no-repeat;
		transition: background 0s ease;
	}
}

.owner-icon {
	position: absolute;
	width: 262px;
	height: 400px;
	transition: transform 1s ease;
	background-image: url(../svg/program-icon.svg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	z-index: $z_index-level1 + 1;
	@include respond($bp-owner-sm) {
		width: 380px;
		height: 579px;
	}
}



.owner-fore {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: $z_index-level1 + 2;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.owner-content {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: $z_index-level1 + 5;
	padding: 24px;
	max-height: 100%;
	overflow: auto;
	@include respond($bp-padding) {
		padding: 24px 60px 40px 60px;	
	}
	.arrow-link {
		color: #fff;
		text-transform: none;		
		letter-spacing: -0.02em;
		@include respond($bp-padding) {
			font-size: 1.8rem;
		}
		.arrow-link-title {
			letter-spacing: 0;
			text-transform: uppercase;
		}
		svg {
			transition: opacity 0.4s ease;			
			flex-shrink: 0;
			* {
				fill: #fff;
			}
			.inner {
				opacity: 0;
			}
			@include respond($bp-padding) {
				width: 40px;
				height: 40px;
			}
		}
	}
}


#owner-rachel {
	background-image: url(../webp/rachel.webp);
	transform: translateX(-80px);
	&.lazyload {
		background-image: none;
		.owner-fore {
			background-image: none;
		}
	}
	.owner-icon {
		top: 65px;
		left: -137px;
		@include respond($bp-owner-sm) {
			top: 165px;
			left: -237px;
		}
	}
	.owner-fore {
		background-image: url(../webp/rachel-fore.webp);
	}
	&:hover,
	&.is-active {
		transform: translateY(-20px);
		.owner-icon {
			transform: translate(40px, -40px);
		}
		svg {
			opacity: 1;
		}
		svg .outer {
			fill: $color-gold !important;
		}
		svg .inner {
			fill: $color-gold !important;
			opacity: 1 !important;
			transform: scale(1.1) !important;	
			transform-origin: center center;		
		}
	}
}

.no-webp #owner-rachel {
	background-image: url(../images/rachel.jpg);
	.owner-fore {
		background-image: url(../images/rachel-fore.png);
	}
	&.lazyload {
		background-image: none;
		.owner-fore {
			background-image: none;
		}
	}
}


#owner-eric {
	transform: translateX(80px);
	background-image: url(../webp/eric.webp);
	&.lazyload {
		background-image: none;
		.owner-fore {
			background-image: none;
		}
	}
	.owner-icon {
		top: -208px;
		right: -100px;
		@include respond($bp-owner-sm) {
			top: -248px;
			right: -200px;
		}
	}
	&:before {
		z-index: $z_index-level1 + 3;
	}
	.owner-fore {
		background-image: url(../webp/eric-fore.webp);
	}
	&:hover,
	&.is-active {
		transform: translateY(-20px);
		.owner-icon {
			transform: translate(-40px, 40px);
		}
		svg {
			opacity: 1;
		}
		svg .outer {
			fill: $color-gold !important;
		}
		svg .inner {
			fill: $color-gold !important;
			opacity: 1 !important;
			transform: scale(1.1) !important;	
			transform-origin: center center;		
		}
	}
}

.owner.is-active:before {
	z-index: $z_index-level1 + 3;
	background: rgba(65, 64, 66, 0.9);
	transition: background 0.4s ease;
}

.no-webp #owner-eric {
	background-image: url(../images/eric.jpg);
	.owner-fore {
		background-image: url(../images/eric-fore.png);
	}
	&.lazyload {
		background-image: none;
		.owner-fore {
			background-image: none;
		}
	}
}


.owner-bio {
	display: none;
	padding: 26px 0 0 0;
	p,
	li {
		color: #fff;
	}
}

.owners-cta {
	text-align: center;
	margin-top: 24px;
	@include respond($bp-mobile) {
		margin-top: 80px;
	}
	.btn {
		opacity: 0;
		transform: translateY(80px);
		transition: color 0.4s ease 0.2s, opacity 0.8s ease, transform 0.8s ease;
	}
}


@include respond($bp-owner-stack, "down") {
	.owner {
		background-size: 100% auto;
		background-position: center top;
		padding-top: calc(100% - 96px);
		&:before {
			display: none;
		}
	}
	.owner-icon-wrap {
		position: absolute;
		overflow: hidden;
		top:0;
		left:0;
		right: 0;
		padding-top: 100%;
	}
	.owner-fore {
		background-size: 100% auto;
		background-position: center top;
		bottom: auto;
		padding-top: 100%;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: -24px;
			height: 230px;
			background: linear-gradient(354.74deg, #403F41 16.88%, rgba(63, 62, 64, 0.5) 56.05%, rgba(63, 62, 64, 0) 81.09%);
		}
	}
	.owner-content {
		position: relative;
		p {
			font-size: 1.4rem;
		}
	}
}



// -----------------------------------------------------------------------------
//! Animation
// -----------------------------------------------------------------------------

.owners .is-scrolled {
	& > .section-title,
	.owner-text,
	.owner-outline,
	.owner {
		transform: translate(0) !important;
		opacity: 1;
	}
	
	.owner:hover,
	.owner:focus {
		transform: translateY(-20px) !important;
	}
	
	.btn {
		transform: translate(0) !important;
		opacity: 1;
	}
}