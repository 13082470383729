$bp-what-stack: 800px;

.whatProgram {
	padding: 80px 0 0 0;
	@include respond($bp-padding) {
		padding: 128px 0 0 0;	
	}
}

.whatProgram .container {
	max-width: 1188px;
	padding: 0 32px;
	@include respond($bp-what-stack) {
		display: flex;
		align-items: center;
		justify-content: space-between;	
	}
}

.whatProgram-image {
	flex: 1 1 480px;
	max-width: 480px;
	position: relative;
	opacity: 0;
	transform: translateX(80px);
	transition: opacity 0.4s ease, transform 0.8s ease;
	img {
		display: block;
		border-radius: 3px;
	}
}

.whatProgram-caption {
	display: flex;
	padding: 20px 0 0 0;
	align-items: flex-start;
	@include respond($bp-mobile) {
		justify-content: flex-end;
	}
	svg {
		flex: 0 0 22px;
		margin: 0 11px 0 0;
	}
	
	.whatProgram-caption-text {
		flex: 1 1 276px;
		max-width: 276px;
		font-size: 1.4rem;
		line-height: calc(23/14);
	}
}

.whatProgram-text {
	padding: 0 0 120px 0;
	position: relative;
	@include respond($bp-outline) {
		padding: 0 0 128px 0;	
	}
	@include respond($bp-what-stack) {
		padding: 0 32px;
		min-width: 330px;	
		max-width: 660px;
		flex: 1 1 calc(100% - 480px);
	}
	@include respond(1240px) {
		padding: 0 210px 120px 0;	
	}
	
	.section-title {
		margin: 0 0 24px 0;
		transform: translateY(40px);
		opacity: 0;
		transition: opacity 0.4s ease, transform 0.8s ease;
		@include respond($bp-split-stack) {
			margin: 0 0 30px 0;
		}
	}
	.split-text-text {
		transform: translateY(40px);
		opacity: 0;
		transition: opacity 0.4s ease 0.2s, transform 0.4s ease 0.2s;
		margin: 0;
	}
}


.whatProgram-outline {
	position: absolute;
	left: 16px;
	top: calc(100% - 120px);	
	height: auto;
	max-width: 86vw;
	transform: translateX(80px);
	opacity: 0;
	transition: opacity 0.4s ease, transform 0.8s ease;
	@include respond($bp-outline, "down") {
		height: 90px;
		max-width: none;
		width: auto;
		left: -16px;
		top: calc(100% - 80px);
	}
	@include respond($bp-what-stack) {
		top: calc(100% + 24px);	
		left: -236px;
	}
	@include respond(1240px) {
		top: calc(100% - 48px);	
	}
}



.whatProgram .is-scrolled {
	.whatProgram-image,
	.whatProgram-outline,
	.section-title,
	.split-text-text {
		transform: translate(0);
		opacity: 1;
	}
}