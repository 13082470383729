.img-block {
    // padding-bottom: 67px;

    // @include respond($md) {
    //     padding-bottom: 150px;
    // }

    img {
        display: block;
        width: 100%;
        height: 100%;
    }

    // .add-padding {
    //     padding-bottom: 67px;

    //     @include respond($md) {
    //         padding-bottom: 150px;
    //     }
    // }

    .text {
        margin-top: 48px;

        h2 {
            font-family: $font;
            font-size: 3.4rem;
            font-style: italic;
            font-weight: 800;
            text-transform: uppercase;
            margin: 0 0 17px;
        }

        h3 {
            font-family: $font;
            font-size: 1.8rem;
            font-style: italic;
            font-weight: 800;
            text-transform: uppercase;
            margin: 20px 0 15px;
        }
    }
}
