

.guide {
	padding: 80px 0 120px 0;
	@include respond($bp-padding) {
		padding: 147px 0 167px 0;
	}
}

.guide-grid {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 15px;
	position: relative;
	@include respond(660px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));	
		gap: 30px 15px;
	}
	@include respond($bp-padding) {
		grid-template-columns: repeat(5, minmax(0, 1fr));	
		gap: 17px;
	}
	
}

.guide-outline {
	position: absolute;
	bottom: calc(100% - 30px);
	left: 24px;	
	max-width: 90vw;
	@include respond(768px) {
		right:116px;	
		left: auto;
	}
	@include respond($bp-outline, "down") {
		height: 45px;
		max-width: none;
		left: -8px;
		bottom: calc(100% - 15px);
		width: auto;
	}
}

.guide-box {
	display: block;
	position: relative;
	width: 100%;
	padding-top: (320 / 250) * 100%;
	opacity: 0;
	transition: opacity 0.8s ease, transform 0.8s ease;
	background-color: $color-border;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	text-decoration: none;
	overflow: hidden;
	border-radius: 3px;
	&:before {
		content:"";
		position: absolute; 
		background: linear-gradient(180deg, #F5F5F6 0%, rgba(249, 250, 250, 0.78) 52.6%, rgba(253, 254, 254, 0) 100%);
		left: 0;
		right: 0;
		top: 0;
		height: 170px;
		z-index: $z_index-level1;
		max-height: 50%;
	}
	&:after {
		content:"";
		position: absolute; 
		background: linear-gradient(70.74deg, #F5F5F6 0%, rgba(245, 245, 246, 0) 28.57%);
		left: 0;
		right: 0;
		bottom: 0;
		height: 144px;
		max-height: 45%;
		z-index: $z_index-level1;
	}
	&.lazyload {
		background-image: none !important;
	}
}

.guide-box-title {
	position: absolute;
	top:0;
	left:0;
	right: 0;
	padding: 20px;
	font-size: 1.6rem;
	line-height: calc(20/16);
	font-weight: 800;
	text-transform: uppercase;
	letter-spacing: -0.02em;
	font-style: italic;
	color: $color-body;
	text-decoration: none;
	z-index: $z_index-level1 + 1;
	@include respond(660px) {
		padding: 29px 40px 0 26px;
		font-size: 1.8rem;
		line-height: calc(22/18);	
	}
	@include respond($bp-padding) {
		font-size: 2.2rem;
		line-height: calc(24/22);
	}
}

.guide-grid.is-scrolled {
	.guide-box {
		opacity: 1;
	}
	.guide-box-up {
		@include respond($bp-padding) {
			transform: translateY(-40px);
		}
	}
	.guide-box-down {
		@include respond($bp-padding) {
			transform: translateY(40px);
		}
	}
	
	.guide-box:hover,
	.guide-box:focus {
		@include respond(660px) {
			transform: translateY(-20px);
		}
		&.guide-box-down{
			@include respond($bp-padding) {
				transform: translateY(20px);
			}
		}
		&.guide-box-up{
			@include respond($bp-padding) {
				transform: translateY(-60px);
			}
		}
		
	}
}
