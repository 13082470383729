$bp-cta-stack: 580px;

.cta {
	padding: 85px 0 80px 0;
	background-color: $color-blue;
	background-image: url(../webp/cta-bg.webp);
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: 275px auto;
	position: relative;
	@include respond($bp-cta-stack) {
		padding: 90px 0 100px 0;
		background-position: right -248px;
		background-size: auto;
	}
	.cta-bg-text {
		position: absolute;
		left: 50%;
		top: 46px;
		transform: translateX(-50%);	
		z-index: $z_index-level1;	
		max-width: 100%;
		height: auto;
		@include respond($bp-cta-stack) {
			top: 50%;
			transform: translate(-50%, -50%);	
		}
	}
}

.cta .container {
	max-width: 1200px;
	
	position: relative;
	z-index: $z_index-level1 + 1;
	@include respond($bp-cta-stack) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.cta-left {
	max-width: 488px;
	flex: 1 1 488px;
	width: 100%;
	padding: 0;
	margin: 0 0 24px 0;
	text-align: center;
	@include respond($bp-cta-stack) {
		text-align: left;
		margin: 0 48px 0 0;
	}
	@include respond($bp-padding) {
		flex: 1 0 488px;	
		margin: 0 118px 0 0;
	}
}

.cta-title {
	color: #fff;
	font-size: 2.4rem;
	line-height: 1;
	margin: 0 0 15px 0;
	text-transform: uppercase;
	font-family: $font;
	font-weight: 800;
	font-style: italic;
	letter-spacing: -0.02em;
	@include respond($bp-cta-stack) {
		font-size: 3.4rem;
		margin: 0 0 12px 0;
		line-height: calc(48/34);
	}
}

.cta-text {
	color: $color-border;
	font-size: 1.6rem;
	line-height: calc(26/16);
	margin: 0;
	@include respond($bp-cta-stack) {
		font-size: 1.7rem;
		line-height: calc(26/17);	
	}
}

.cta-right {
	flex: 1 1 auto;
}

.cta-right .button-row {
	@include respond($bp-cta-stack, "down") {
		justify-content: center;
		.btn {
			margin: 0 8px 16px 8px;
		}
	}
}