.banner {
	background: $color-blue;
	padding: 12px 0 13px 0;
	transform: translateY(-100%);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	.container {
		max-width: 1280px;
		padding: 0 32px;
		position: relative;
		text-align: center;
	}
	
	p {
		color: #fff;
		font-size: 1.4rem;
		line-height: calc(21/14);
		margin: 0;
		@include respond($bp-padding) {
			font-size: 1.6rem;
			line-height: calc(25/16);	
		}
		a {
			color: #fff;
			text-decoration: underline;
			&:hover {
				color: $color-gold;
			}
		}
	}
	
	.banner-close {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		width: 32px;
		height: 32px;
		display: block;
		padding: 0;
		cursor: pointer;
		background: transparent;
		border: none;
		padding: 8px;
		svg {
			width: 16px;
			height: 16px;
			display: block;
		}
	}
}
