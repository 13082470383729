$bp-buy-xl: 1240px;
$bp-buy-stack: 768px;
$bp-buy-sm: 400px;

.buyTabs {
	
}

.buyTabs-nav {
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	overflow-x: auto;
	border-bottom: 1px solid $color-border;
	position: relative;
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		padding: 0;
		margin: 0;
	}
}

.buyTabs-highlight {
	position: absolute;
	bottom: 0;
	left:0;
	height: 3px;
	background: $color-gold;
	width: 0;
	transition: all 0.4s ease;
}

.buyTabs-nav-label {
	white-space: nowrap;
	margin: 0 40px 0 0;
	padding: 20px 0;
	position: relative;
	background: transparent;
	border: none;
	color: $color-dark;
	transition: color 0.4s ease;
	font-style: italic;
	font-weight: 800;
	font-size: 1.8rem;
	line-height: calc(22/18);
	text-transform: uppercase;
	text-align: left;
	@include respond($bp-mobile, "down") {
		margin: 0 20px 0 0;
	}
	@include respond($bp-padding) {
		font-size: 2.2rem;
		line-height: calc(24/22);
	}
}

.buyTabs-button {
	white-space: nowrap;
	margin: 0 40px 0 0;
	padding: 20px 0;
	position: relative;
	background: transparent;
	border: none;
	color: rgba(65, 64, 66, 0.5);
	transition: color 0.4s ease;
	font-style: italic;
	font-weight: 800;
	font-size: 1.8rem;
	line-height: calc(22/18);
	text-transform: uppercase;
	text-align: left;
	@include respond($bp-padding) {
		display: block !important;
		height: auto !important;
		overflow: visible !important;
		margin: 0 40px 0 0 !important;
		padding: 20px 0 !important;
	}
	@include respond($bp-mobile, "down") {
		margin: 0 20px 0 0;
	}
	@include respond($bp-padding) {
		font-size: 2.2rem;
		line-height: calc(24/22);
	}
	&:after {
		// content: "";
		// position: absolute;
		// bottom: 0;
		// left:0;
		// height: 3px;
		// background: $color-gold;
		// width: 0;
		// transition: width 0.4s ease;
	}
	&:hover,
	&:focus,
	&.is-active {
		color: $color-gold;
		// &:after {
		// 	width: 100%;
		// }
	}
}

.buyTabs-panels {
	padding: 40px 0 0 0;
	@include respond($bp-mobile) {
		padding: 55px 0 0 0;
	}
}


.buyTabs-panel {
	display: none;
}

#buyTabs-panel-1 {
	display: block;
}

.buyTabs-panel-grid {
	display: grid;
	
	gap: 24px;
	grid-template-columns: 1fr;
	@include respond($bp-buy-stack) {
		grid-auto-rows: 1fr;
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	@include respond($bp-padding) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

.buyTabs-box {
	background-color: #fff;
	text-align: center;
	border-radius: 3px;
	border: 1px solid $color-border;
	padding: 20px 27px 92px 27px;
	position: relative;
	.zing-btn {
		bottom: 30px;
		left: 27px;
		right: 27px;
	}
	@include respond($bp-buy-sm) {
		padding: 30px 37px 102px 37px;
		.zing-btn {
			bottom: 40px;
			left: 37px;
			right: 37px;
		}
	}
	@include respond($bp-buy-stack) {
		padding: 20px 27px 92px 27px;
		.zing-btn {
			bottom: 30px;
			left: 27px;
			right: 27px;
		}
	}
	@include respond($bp-buy-xl) {
		padding: 30px 37px 102px 37px;
		.zing-btn {
			bottom: 40px;
			left: 37px;
			right: 37px;
		}
	}
	&.is-highlighted {
		border: 3px solid $color-gold;
		padding: 18px 24px 80px 24px;
		.zing-btn {
			bottom: 18px;
			left: 24px;
			right: 24px;
		}
		@include respond($bp-buy-sm) {
			padding: 28px 35px 100px 35px;
			.zing-btn {
				bottom: 38px;
				left: 35px;
				right: 35px;
			}
		}
		@include respond($bp-buy-stack) {
			padding: 18px 25px 90px 25px;
			.zing-btn {
				bottom: 28px;
				left: 25px;
				right: 25px;
			}
		}
		@include respond($bp-buy-xl) {
			padding: 28px 35px 100px 35px;	
			.zing-btn {
				bottom: 38px;
				left: 35px;
				right: 35px;
			}
		}
	}
}

.buyTabs-box-preTitle {
	line-height: 14px;
	min-height: 14px;
	font-size: 1.1rem;
	text-transform: uppercase;
	font-style: italic;
	font-weight: 800;
	color: $color-gold;
	@include respond($bp-mobile) {
		font-size: 1.3rem;
	}
}

.buyTabs-box-title {
	font-style: italic;
	text-transform: uppercase;
	font-weight: 800;
	font-size: 1.8rem;
	line-height: calc(22/18);
	font-family: $font;
	margin: 9px 0 12px 0;
	@include respond($bp-mobile) {
		margin: 9px 0 19px 0;
	}
	@include respond($bp-padding) {
		font-size: 2.2rem;
		line-height: calc(24/22);
	}
}

.buyTabs-box-price {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	font-style: italic;
	font-size: 4rem;
	line-height: 1;
	color: $color-gold;
	font-weight: 800;
	text-transform: uppercase;
	span {
		font-size: 1.3rem;
		margin:0 0 8px 4px;
		&.buyTabs-box-original {
			font-size: 2.2rem;
			color: #A09FA0;
			text-decoration: line-through;
		}
	}
	@include respond($bp-padding) {
		font-size: 4.8rem;
	}
}

.buyTabs-box-subtitle {
	margin-top: 12px;
}


.buyTabs-switch {
	position: relative;
	background: $color-border;
	border: 3px solid $color-border;
	border-radius: 100px;
	display: inline-flex;
	align-items: stretch;
	justify-content: center;
	margin: 20px 0 0 0;
	@include respond($bp-mobile, "down") {
		margin: 12px 0 0 0;
		font-size: 1.1rem;
	}
}

.buyTabs-switch-button {
	letter-spacing: -0.02em;
	font-size: 1.1rem;
	line-height: 15px;
	font-weight: bold;
	text-transform: uppercase;
	border-radius: 100px;
	padding: 8px;
	background: transparent;
	transition: background 0.4s ease, color 0.4s ease;
	color: rgba($color-body, 0.75);
	position: relative;
	z-index: $z_index-level1 + 1;
	margin: 0;
	@include respond($bp-padding) {
		padding: 10px;	
		font-size: 1.1rem;
	}
	@include respond($bp-buy-xl) {
		padding: 10px 14px;	
	}
	&.is-active {
		//background: #fff;
		//color: $color-body;
	}
	&:hover,
	&:focus {
		color: $color-body;
	}
}

.buyTabs-button-highlight {
	border-radius: 100px;
	background: #fff;
	height:100%;
	width: 0;
	position: absolute;
	left: 0px;
	z-index: $z_index-level1;
}

.buyTabs-switch.only-one .buyTabs-button-highlight {
	width: 100% !important;
}

.only-one .buyTabs-switch-button {
	pointer-events: none;
}

.buyTabs-content {
	text-align: left;
	padding: 20px 0 0 0;
	margin: 16px 0 0 0;
	border-top: 1px solid $color-border;
	@include respond($bp-mobile) {
		padding: 23px 0 0 0;
		margin: 28px 0 0 0;	
	}
	p {
		text-align: center;
		@include respond($bp-mobile, "down") {
			font-size: 1.4rem;
			line-height: calc(21/14);
		}
	}
}

.buyTabs-content li {
	margin: 0 0 15px 0;
	padding: 0 0 0 30px;
	list-style: none;
	position: relative;
	font-size: 1.6rem;
	line-height: calc(25/16);
	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 6px;
		width: 15px;
		height: 12px;
		background-size: auto;
		background-repeat: no-repeat;
		background-position: left center;
		background-image: url(../svg/check-gold.svg);
		
	}
}

.buyTabs-box-per {
	margin: 10px 0 0 0;
	@include respond($bp-mobile) {
		margin: 16px 0 0 0;
	}
}

.buyTabs-box-savings {
	font-size: 1.3rem;
	line-height: calc(15/13);
	padding: 4px 8px;
	border-radius: 3px;
	color: $color-gold;
	background: rgba($color-gold, 0.1);
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: -0.02em;
	font-weight: 700;
	margin: 11px 0 -5px 0;
}

.zing-btn {
	position: absolute;
}

.buyTabs-dropdown {
	display: none;
}

@include respond($bp-padding, "down") {
	.buyTabs-highlight {
		display: none;
	}
	.buyTabs-nav {
		display: grid;
		border: none;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		overflow-x: hidden;
		gap: 16px;
	}
	
	.buyTabs-button {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-family: $font;
		font-weight: bold;
		text-transform: uppercase;
		border-radius: 2px;
		text-decoration: none;
		letter-spacing: -0.02em;
		font-size: 1.1rem;
		line-height: calc(12/11);
		padding: 16px 24px;
		position: relative;
		overflow: hidden;
		transition: color 0.4s ease 0.2s;
		background-color: #fff;
		color: $color-body;
		cursor: pointer;
		font-family: $font;
		font-style: normal;
		border: 1px solid $color-border;
		margin: 0;
		@include respond($bp-btn-sm) {
			line-height: calc(14/13);
			padding: 20px 28px;
			font-size: 1.3rem;
		}
		span {
			position: relative;
			z-index: $z_index-level1 + 2;
			display: block;
		}
		&:after {
			content:"";
			position: absolute;
			z-index: $z_index-level1 + 1;
			top:0;
			left: 0;
			bottom: 0;
			width: 0%;
			background-color: $color-gold;
			transition: width 0.4s cubic-bezier(0.42, 0, 1.0, 0.4);
		}
		&:hover,
		&:focus,
		&.is-active {
			color: #fff;
		}
		&:hover:after,
		&:focus:after,
		&.is-active:after {
			width: 100%;
		}
	}
	
}

@include respond(700px, "down") {
	.buyTabs-nav {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}
