$bp-split-stack: 768px;
$bp-fit-stack: 900px;
$bp-fit-quad: 400px;

.fit {
	background: linear-gradient(180deg, #414042 0%, #202020 100%);
	padding: 80px 0;
	position: relative;
	z-index: $z_index-level1 + 1;
	@include respond($bp-padding) {
		padding: 137px 0 151px 0;
	}
	
	&:after {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(-130px, -50%);
		width: 122px;
		height: 56px;
		background-image: url(../svg/xx.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		@include respond(768px, "down") {
			width: 78px;
			height: 36px;
			transform: translate(-34px, -50%);
		}
	}
}

.fit .container .container {
	padding: 0;
}

.fit .split {
	padding: 0 0 80px 0;
	@include respond($bp-padding) {
		padding: 0 0 116px 0;
	}
	.split-text h2.section-title{
		color: #fff;
	}
	.split-text p  {
		
		color: $color-border;
	}
	
	.split-image {
		order: 3;
		&:before {
			display: none;
		}
	}
	.split-text {
		padding-left: 0;
		max-width: 596px;
	}
	.split-text p {
		max-width: 450px;
	}
	
	.split-text a {
		color: $color-gold;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	
	.split-stats {
		left: 0;
		right: auto;
		transform: translate(0, -50%);
		@include respond(1300px) {
			transform: translate(-80px, -50%);	
		}
	}
}

.fit .altblock {
	padding: 80px 0 0 0;
	margin: 0;
	@include respond($bp-padding) {
		padding: 116px 0 0 0;
	}
	.altblock-content {
		max-width: 480px;
		h2 {
			color: #fff;
		}
		p,
		li {
			color: $color-border;
		}
	}
	.boxing-title {
		line-height: calc(30/28);
		@include respond($bp-padding) {
			font-size: 2.8rem;
		}
		span {
			color: $color-gold;
		}
	}
	
	.altblock-text {
		margin-bottom: 43px;
		small {
			color: $color-border;
		}
	}
}


.fit-split {
	max-width: 768px;
	margin: 0 auto;
	@include respond($bp-fit-stack) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;	
		max-width: 100%;
	}
}

.fit-split-left {
	position: relative;
	z-index: $z_index-level1 + 1;
	@include respond($bp-fit-stack) {
		flex: 0 0 480px;
		max-width: 480px;
	}
}


.fit-stats-box {
	background: #fff;
	border-radius: 3px;
	overflow: hidden;
}

.fit-stats-images {
	padding: 24px 12px 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	@include respond($bp-mobile) {
		padding: 24px 16px;		
	}
}

.fit-stats-image {
	flex: 0 0 calc(44% - 16px);
	width: calc(44% - 16px);
	text-align: center;
	padding: 0 16px 0 0;
	@include respond($bp-mobile) {
		flex: 0 0 calc(50% - 16px);
		width: calc(50% - 16px);
	}
	img {
		display: block;
		margin: 0 auto;
		max-width: 100%;
	}
	&:last-of-type {
		padding: 0 0 0 16px;
		width: calc(44% - 17px);
		flex: 0 0 calc(44% - 17px);
		@include respond($bp-mobile) {
			width: calc(50% - 1px);
			flex: 0 0 calc(50% - 17px);
		}
		border-left: 1px solid $color-border;
	}
}
.fit-stats-image-title {
	text-transform: uppercase;
	font-size: 0.9rem;
	line-height: calc(10/9);
	margin: 0 0 1px 0;
	font-weight: bold;
	text-align: center;
}
.fit-stats-trio {
	background: #fff;
	padding-bottom: 13px;
}

.fit-stats-quad {
	background: $color-gold;
	padding-bottom: 13px;
}

.fit-stats-header {
	background: $color-border;
	font-size: 1rem;
	line-height: 2.4;
	text-transform: uppercase;
	font-weight: bold;
	text-align: center;	
	font-family: $font;
	margin: 0 0 13px 0;
}

.fit-stats-quad .fit-stats-header {
	color: #fff;
	background: #C5AA76;
}

.fit-stats-grid {
	background: $color-border;
	gap: 0 1px;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
}

.fit-stats-stat {
	background: #fff;
	text-align: center;
	.fit-stats-stat-title {
		text-transform: uppercase;
		font-size: 0.9rem;
		line-height: calc(10/9);
		margin: 0 0 1px 0;
		font-weight: bold;
	}
	.fit-stats-stat-num {
		font-size: 2.2rem;
		line-height: calc(26/22);
		font-weight: 800;
		font-style: italic;
		@include respond($bp-padding) {
			font-size: 1.8rem;
			line-height: calc(26/18);
		}
		span {
			font-weight: normal;
			font-size: 1.4rem;
			line-height: calc(26/14);
			font-style: normal;
			display: inline-block;
			margin: 0 0 0 4px;
			@include respond($bp-mobile) {
				font-size: 1rem;
				line-height: calc(26/10);	
			}
		}
	}
}

.fit-stats-quad .fit-stats-grid {
	background: rgba(#fff, 0.2);
	grid-template-columns: repeat(2, minmax(0, 1fr));
	@include respond($bp-fit-quad) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
	.fit-stats-stat {
		background: $color-gold;
		@include respond($bp-fit-quad, "down") {
			padding: 8px 0;
		}
		.fit-stats-stat-title,
		.fit-stats-stat-num  {
			color: #fff;
		}
		.fit-stats-stat-num {
			@include respond($bp-padding) {
				font-size: 2.4rem;
				span {
					font-size: 1.4rem;
				}	
			}
		}
	}
}

.fit-split-right {
	position: relative;
	padding: 40px 0 0 0;
	@include respond($bp-fit-stack) {
		flex: 1 1 calc(100% - 480px);
		width: calc(100% - 480px);
		max-width: 610px;
		padding: 0 0 0 40px;
	}
	@include respond(1200px) {
		padding: 0 130px 0 80px;	
		max-width: 660px;
	}
}

.fit-swiper {
	position: relative;
	z-index: $z_index-level1 + 1;
}

.fit-split-slide {
	max-width: 100%;
	.fit-split-slide-name {
		color: $color-gold;
		text-transform: uppercase;
		font-size: 1.8rem;
		line-height: calc(20/18);
		font-family: $font;
		margin: 0 0 23px 0;
		font-weight: 800;
		letter-spacing: -0.02em;
		font-style: italic;
	}
	p {
		margin: 0;
		font-size: 1.6rem;
		line-height: calc(24/16);
		font-weight: 700;
		color: #fff;
		letter-spacing: -0.02em;
		@include respond($bp-padding) {
			font-size: 1.8rem;
			line-height: calc(27/18);
		}
	}
}
.fit-controls {
	padding: 49px 0 0 0;
	display: grid;
	grid-template-columns: 112px 1fr 112px;
	align-items: center;
	gap: 0;
	@include respond($bp-mobile, "down") {
		grid-template-columns: 92px 1fr 92px;	
	}
}

.fit-arrows {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.fit-button-prev,
.fit-button-next {
	z-index: $z_index-level1 + 3;
	cursor: pointer;
	svg {
		width: 38px;
		height: 40px;
		@include respond($bp-mobile, "down") {
			width: 28px;
			height: 29px;
		}
	}
	&:hover,
	&:focus {
		svg .arrow {
			fill: #fff;
		}
	}
}

.fit-button-prev {
	svg {
		transform: rotate(180deg);
	}
}

.fit-split-slide {
	
}

.fit .swiper-wrapper {
	@include respond($bp-fit-stack) {
		align-items: center;
	}
}

.swiper-container-horizontal  .swiper-pagination-bullets.fit-pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	top: -2px;
	@include respond($bp-mobile, "down") {
		padding-left: 24px;
	}
}
.swiper-container-horizontal  .swiper-pagination-bullets.fit-pagination {

	.swiper-pagination-bullet {
		background: #fff;
		opacity: 0.6;
		transition: all 0.4s ease;
		width: 6px;
		height: 6px;
		border-radius: 3px;
		margin: 0 5px;
		&.swiper-pagination-bullet-active {
			opacity: 1;
			width: 22px;
		}
	}
}

.track-outline {
	position: absolute;
	right: 25px;
	bottom: calc(100% - 120px);
	max-width: 95vw;
	height: auto;
	z-index: $z_index-level1;
	@include respond($bp-fit-stack) {
		bottom: calc(100% - 53px);
	}
	@include respond(1200px) {
		right: 275px;	
	}
	@include respond($bp-outline, "down") {
		height: 45px;
		max-width: none;
		width: auto;
		left: 105px;
		right: auto;
		bottom: auto;
		top: -610px
	}
}