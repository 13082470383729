.logoGrid {
    text-align: center;
    // padding-top: 67px;
    // padding-bottom: 67px;

    // @include respond($md) {
    //     padding-top: 150px;
    //     padding-bottom: 150px;
    // }

    &.grayBg {
        background-color: $color-gray;

        .logo {
            background: #ffffff !important;
        }

        .container {
            padding-top: 67px;
            padding-bottom: 67px;

            @include respond($md) {
                padding-top: 150px;
                padding-bottom: 150px;
            }
        }
    }

    .logoGrid-text {
        max-width: 700px;
        margin: 0 auto;
        margin-bottom: 48px;
    }

    h2 {
        font-family: $font;
        font-size: 3.4rem;
        font-style: italic;
        font-weight: 800;
        text-transform: uppercase;
        margin: 0 0 17px;
    }

    h3 {
        font-family: $font;
        font-size: 1.8rem;
        font-style: italic;
        font-weight: 800;
        text-transform: uppercase;
        margin: 20px 0 15px;
    }

    .logoGrid-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2px;

        @include respond($md) {
            grid-template-columns: 1fr 1fr;
        }

        @include respond($lg) {
            grid-template-columns: repeat(4, 1fr);
        }

        .logo {
            display: flex;
            justify-content: center;
            background: $color-gray;
            padding: 30px 0;

            @include respond($md) {
                padding: 40px 0;
            }
        }
    }
}
