$bp-form-stack: 680px;

form {
    .freeform-row {
    }

    .freeform-row .freeform-column {
        padding-top: 18px;
        padding-bottom: 18px;
    }

    .freeform-row .freeform-column .freeform-label {
        font-size: 1.3rem;
        text-transform: uppercase;
        color: $color-body;
        margin: 0 0 0 0;
        font-family: $font;
        color: $color-body;
    }

    .freeform-row .freeform-column .freeform-label.freeform-required::after {
        color: $color-gold;
    }

    .freeform-row .freeform-column .freeform-input {
        line-height: 40px;
        height: 40px;
        border-bottom: 1px solid #ccc;
        padding: 0;
        font-size: 1.6rem;
        font-family: $font;
        color: $color-body;
        background-color: transparent;
        &:focus {
            border-color: $color-gold;
        }
    }

    .freeform-row .freeform-column textarea.freeform-input {
        height: 98px;
        resize: vertical;
        line-height: 24px;
    }

    .freeform-row .freeform-column select.freeform-input {
        background: none;
        border-top: none;
        border-left: none;
        border-right: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url(../svg/calendarFilter-default.svg);
        background-size: auto;
        background-repeat: no-repeat;
        background-position: right center;
        &:hover,
        &:focus {
            background-image: url(../svg/calendarFilter-hover.svg);
        }
    }

    .freeform-submit-button {
        width: 100%;
        color: #fff;
    }

    .freeform-column .btn {
        @include respond($bp-form-stack, "down") {
            width: 100%;
        }
    }

    .ff-form-errors,
    .ff-form-success {
        padding: 16px;
        border: none;
        background: $color-gold;
        border-radius: 2px;
        color: #fff;
        text-align: center;
        p {
            margin: 0;
            font-size: 1.3rem;
            line-height: calc(21 / 13);
        }
    }

    .freeform-row .freeform-column ul.ff-errors > li {
        font-size: 1.3rem;
        line-height: calc(21 / 13);
        color: $color-gold;
        padding: 0;
    }

    .freeform-column-6 {
        @include respond($bp-form-stack, "down") {
            width: 100% !important;
        }
    }
}

.formBlock {
    // padding-top: 67px;
    // padding-bottom: 67px;

    // @include respond($md) {
    //     padding-top: 150px;
    //     padding-bottom: 150px;\
    // }

    .form-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include respond($md) {
            flex-direction: row;
            gap: 230px;
        }
    }

    h3 {
        font-family: $font;
        font-style: italic;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 3.4rem;
        margin: 0 0 17px;
    }

    p {
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
    }

    .cf-intro-text {
        flex: 1;
    }

    .cf-form {
        margin: 0 auto;
        flex: 1;
    }
}
