$bp-expanders-stack: 800px;

.faq {
	border-top: 1px solid $color-border;
	&:first-of-type {
		border: none;
	}
}

.faq-title {
	width: 100%;
	display: flex;
	font-family: $font;
	color: $color-body;
	align-items: center;
	position: relative;
	justify-content: space-between;
	background: transparent;
	padding: 20px 0;
	letter-spacing: -0.02em;
	font-size: 1.6rem;
	line-height: 150%;
	border: none;
	cursor: pointer;
	font-weight: 700;
	text-align: left;
	
	@include respond($bp-padding) {
		font-size: 1.8rem;
	}
	
	
	&:before {
		content: "";
		position: absolute;
		width: 78px;
		height: 36px;
		background-image: url(../svg/xx.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		top: 50%;
		right:calc(100% + 11px);
		z-index: $z_index-level1 + 1;
		opacity: 0;
		transition: opacity 0.4s ease;
		transform: translateY(-50%);
		@include respond(1200px) {
			right:calc(100% + 98px);	
		}
		@include respond($bp-expanders-stack, "down") {
			display: none;
		}
	}
	
	svg {
		flex: 0 0 18px;
		width: 18px;
		height: 18px;
		margin: 0 0 0 40px;
		.outer {
			transition: fill 0.2s ease;
		}
		.inner {
			opacity: 0;
			transition: opacity 0.2s ease;
		}
	}
	
	&.is-active,
	&:hover,
	&:focus {
		svg .outer {
			fill: $color-gold;
		}
		svg .inner {
			opacity: 1;
		}
		
	}
	
	&.is-active {
		font-weight: 800;
		font-style: italic;		
		font-size: 2.2rem;
		line-height: 92.85714286%;
		text-transform: uppercase;
		&:before {
			opacity: 1;
		}
	}
	
	
	
	
}

.faq-text {
	display: none;
	p,ul,ol {
		margin-bottom: 20px;
	}
}