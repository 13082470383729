.updates {
	padding: 80px 0 90px 0;
	background: linear-gradient(180deg,  rgba(230, 231, 232, 0) 0%, rgba(230, 231, 232, 0.4) 100%);
	@include respond($bp-padding) {
		padding: 111px 0 144px 0;
	}
}

.updates .container {
	position: relative;
}

.updates-text-outline {
	position: absolute;
	left: -75px;
	bottom: -86px;
	width: 946px;
	max-width: 90vw;
	height: auto;
	@include respond(500px, "down") {
		left: 12px;
		bottom: -120px;
	}
	@include respond($bp-outline, "down") {
		height: 90px;
		max-width: none;
		width: auto;
	}
}

.updates .titleSplit {
	@include respond($bp-mobile, "down") {
		margin: 0 0 40px 0;
	}
}