$bp-nav-lg: 1024px;
$bp-nav-stack: 768px;

.nav {
	background-color: $color-blue;
	background-image: url(../webp/nav-bg.webp);
	background-repeat: no-repeat;
	background-position: bottom right;
	background-size: 274px auto;
	position: fixed;
	display: none;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: $z_index-level6;
	@include respond($bp-nav-stack) {
		background-size: auto;
	}
}

.nav-close,
.modal-close {
	width: 48px;
	height: 40px;
	background: transparent;
	padding: 0 5px;
	margin: 0 -5px 0 0;
	display: block;
	position: relative;
	transform: rotate(-45deg);
	transition: transform 0.4s ease;
	.bar {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		height: 3px;
		width: 30px;
		&:nth-child(2){
			height: 30px;
			width: 3px;
		}
	}
	&:hover,
	&:focus {
		transform: rotate(45deg)
	}
}



.modal-close {
	position: absolute;
	top: -20px;
	right: -20px;
}



.nav-inner {
	height: calc(100vh - 98px);
	position: absolute;
	top: 98px;
	left: 0;
	right: 0;
	overflow: auto;
	padding: 24px 24px 80px 24px;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	
	@include respond($bp-padding) {
		padding: 32px;
	}
}

.no-touchevents .nav-inner {
	@include respond($bp-nav-stack) {
		top: 98px;
		height: calc(100vh - 98px);
	}
}

.nav-inner-wrap {
	max-width: 936px;
	margin: auto;
	width: 100%;
}

.nav-inner-main {
	ul {
		list-style: none;
		margin: 0;
		padding: 0 20px;
	}
	ul li {
		padding: 0;
		margin: 0;
	}
	ul li a {
		text-decoration: none;
		color: #fff;
	}
	
	ul > li:hover > a {
		color: $color-gold;
	}
}

.no-touchevents .nav-inner-main ul li a {
	&:hover,
	&:focus {
		color: $color-gold;
	}
}
.nav-inner-main > ul {
	
	@include respond($bp-nav-lg) {
		padding-right: 0;
	}
}

.no-touchevents .nav-inner-main > ul {
	@include respond($bp-nav-stack) {
		width: 50%;
		padding-right: 0px;
		position: relative;
	}	
}

.nav-inner-main > ul > li {
	font-size: 2rem;
	line-height: calc(22/20);
	margin: 0 0 20px 0;
	font-weight: 800;
	font-style: italic;
	text-transform: uppercase;
	position: relative;
	
	@include respond($bp-nav-lg) {
		font-size: 3.4rem;
		line-height: calc(38/34);
		padding-right: 58px;
	}
}
.no-touchevents .nav-inner-main > ul > li {
	@include respond($bp-nav-lg) {
		
		&.is-hovered .sub-menu {
			padding: 0 0 0 77px;	
		}
	}
}

.no-touchevents .nav-inner-main > ul > li {
	@include respond($bp-nav-stack) {
		font-size: 2.8rem;
		line-height: calc(32/28);
		margin: 0 0 25px 0;
		position: static;
		padding-right: 32px;
			
		&.is-hovered .sub-menu {
			opacity: 1;
			pointer-events: auto;
			padding: 0 0 0 32px;	
			transition: opacity 0.4s ease, padding 0.4s ease;
		}	
	}
}

.no-touchevents .nav-inner-main > ul > li > a:focus ~ .sub-menu,
.no-touchevents .nav-inner-main > ul > li > .sub-menu.is-focused {
	@include respond($bp-nav-stack) {
		opacity: 1;
		pointer-events: auto;
		padding: 0 0 0 32px;		
	}
	@include respond($bp-nav-lg) {
		padding: 0 0 0 32px;
	}
}

.nav-inner-main > ul > li > .sub-menu {
	display: none;
	padding: 24px 0 8px 27px;
	border-bottom: 1px solid rgba(255,255,255,0.2);
	
	
	
	li {
		font-size: 1.5rem;
		line-height: calc(17/15);
		font-weight: 700;
		font-style: normal;
		text-transform: none;
		margin: 0 0 17px 0;
		
	}
}

.no-touchevents .nav-inner-main > ul > li > .sub-menu {
	@include respond($bp-nav-stack) {
		display: flex !important;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0s ease, padding 0s ease;
		position: absolute;
		border-bottom: none;
		border-left: 1px solid rgba(255,255,255,0.2);
		left: 100%;
		width: 100%;
		top: 0;
		height: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		padding: 0 0 0 16px;
		@include respond($bp-nav-lg) {
			padding: 0 0 0 16px;	
		}
	}
	
	li {
		@include respond($bp-nav-stack) {
			font-size: 2.2rem;
			line-height: calc(24/22);
			margin: 0 0 28px 0;
			&:last-of-type {
				margin: 0;
			}
		}
	}
}

.sub-menu-toggle {
	padding: 0;
	position: absolute;
	background: transparent;
	right: 0;
	top: 0;
	width: 22px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	
	svg {
		width: 16px;
		height: 16px;
	}
	.inner {
		opacity: 0;
		transition: all 0.4s ease;
	}
	&:hover .inner,
	&.is-active .inner {
		opacity: 1;
		transform-origin: center center;
		transform: scale(1.1);
	}
}

.no-touchevents .sub-menu-toggle {
	@include respond($bp-nav-stack) {
		display: none;
	}
}

.nav-inner-sub {
	padding: 76px 0 0 20px;
	
	ul {
		list-style: none;
		margin: 0;
		padding: 0 20px 0 0;
		
	}
	ul li {
		padding: 0;
		margin: 0;
		font-size: 1.3rem;
		line-height: calc(14/13);
		font-weight: 700;		
		text-transform: uppercase;
		margin: 0 0 16px 0;
		position: relative;
		&:last-of-type{
			margin: 0;
		}
		
	}
	ul li a {
		color: #fff;
		text-decoration: none;
	}
	.sub-menu {
		display: none;
		
		left: 0;
		top: 100%;
		li {
			font-weight: 400;
			font-size: 1.4rem;
			line-height: calc(16/14);
			padding: 14px 0 0 0;
			white-space: nowrap;
			margin-right: 0;
		}
		
	}
	.sub-menu-toggle {
		position: relative;
		display: inline-block;
		margin: 0 0 0 8px;
		top: 2px;
		
	}
}

.no-touchevents .nav-inner-sub {
	@include respond($bp-nav-stack) {
		padding: 80px 0 90px 20px;	
	}
	ul {
		@include respond($bp-nav-stack) {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 0;
		}
	}
	ul li {
		@include respond($bp-nav-stack) {
			font-size: 1.6rem;
			letter-spacing: -0.02em;
			line-height: calc(18/16);
			margin:0 32px 0 0;
		}
	}
	.sub-menu {
		@include respond($bp-nav-stack) {
			display: block !important;
			position: absolute;
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.4s ease;
		}
	}
	.sub-menu-toggle {
		@include respond($bp-nav-stack) {
			display: none;
		}
	}
}

.sub-nav-indicator {
	display: none;
	
	svg {
		width: 12px;
		height: 12px;
	}
	.inner {
		opacity: 0;
		transition: all 0.4s ease;
	}
}

.no-touchevents .sub-nav-indicator {
	@include respond($bp-nav-stack) {
		display: inline-block;
		margin-left: 4px;
	}
}

.no-touchevents .nav-inner-sub ul > li:hover > a,
.no-touchevents .nav-inner-sub ul > li > a:focus {
	color: $color-gold;
}
.nav-inner-sub ul > li:hover .sub-nav-indicator .inner {
	opacity: 1;
	transform-origin: center center;
	transform: scale(1.1);
}

@include respond($bp-nav-stack, "down") {
	.nav-inner-sub ul > li .sub-menu {
		border-bottom: 1px solid rgba(255,255,255, 0.2);
		padding: 16px 20px;	
	}
}

.touchevents {
	.nav-inner-sub ul > li .sub-menu {
		border-bottom: 1px solid rgba(255,255,255, 0.2);
		padding: 16px 20px;	
	}
}

.no-touchevents {
	@include respond($bp-nav-stack) {
		.nav-inner-sub ul > li:hover .sub-menu,
		.nav-inner-sub ul > li > a:focus ~ .sub-menu,
		.nav-inner-sub ul > li.is-focused .sub-menu {
			opacity: 1;
			pointer-events: auto;
			
		}
	}
}

@media (max-height: 900px) {
	.nav-inner-sub {
		padding-top: 40px;
	}
}

@media (max-height: 800px) {
	.nav-inner {
		top: 98px;
	}
}